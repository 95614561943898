<template>
  <div class="drill">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'DrillContainer',
  data() {
    return {
    }
  }
}
</script>
