/**
 * 碎片
 * 碎片API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import CreatedVo from '../../fragment/model/CreatedVo';
import FragmentDetailVo from '../../fragment/model/FragmentDetailVo';
import FragmentHomePageVo from '../../fragment/model/FragmentHomePageVo';
import FragmentStarForm from '../../fragment/model/FragmentStarForm';
import KnowledgeFragmentListVo from '../../fragment/model/KnowledgeFragmentListVo';
import MajorStatisticsDetailVo from '../../fragment/model/MajorStatisticsDetailVo';
import PagingFragmentVo from '../../fragment/model/PagingFragmentVo';
import PagingStarFragmentVo from '../../fragment/model/PagingStarFragmentVo';
import UserFragmentHistoryForm from '../../fragment/model/UserFragmentHistoryForm';
  /**
   * Fragment service.
   * @module fragment/api/FragmentApi
   * @version 0.2.0
   */

  /**
   * Constructs a new FragmentApi. 
   * @alias module:fragment/api/FragmentApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * 碎片详情
     * @param {Integer} id id
     * @param {Object} opts Optional parameters
     * @param {Integer} opts.courseStandardId 课程标准id,查询是否在课程中收藏
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/FragmentDetailVo}
     */
this.getFragment = function(id, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id == undefined || id == null) {
        throw "Missing the required parameter 'id' when calling getFragment";
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
        'courseStandardId': opts['courseStandardId']
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = FragmentDetailVo;
      return this.apiClient.callApi(
        '/api/fragment/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 专业碎片统计详情
     * @param {Integer} majorId 专业id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/MajorStatisticsDetailVo}
     */
this.getFragmentStatisticsByMajor = function(majorId) {
      var postBody = null;

      // verify the required parameter 'majorId' is set
      if (majorId == undefined || majorId == null) {
        throw "Missing the required parameter 'majorId' when calling getFragmentStatisticsByMajor";
      }


      var pathParams = {
      };
      var queryParams = {
        'majorId': majorId
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = MajorStatisticsDetailVo;
      return this.apiClient.callApi(
        '/api/fragment/statistics_by_major', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 获取云碎片首页
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/FragmentHomePageVo}
     */
this.getHomePage = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = FragmentHomePageVo;
      return this.apiClient.callApi(
        '/api/fragment/home_page', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 知识点碎片列表
     * @param {Integer} courseStandardId 课程标准id
     * @param {Integer} knowledgeId 课程目标知识点id
     * @param {Object} opts Optional parameters
     * @param {Integer} opts.companyId 碎片制作单位
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/KnowledgeFragmentListVo}
     */
this.getKnowledgeFragmentList = function(courseStandardId, knowledgeId, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'courseStandardId' is set
      if (courseStandardId == undefined || courseStandardId == null) {
        throw "Missing the required parameter 'courseStandardId' when calling getKnowledgeFragmentList";
      }

      // verify the required parameter 'knowledgeId' is set
      if (knowledgeId == undefined || knowledgeId == null) {
        throw "Missing the required parameter 'knowledgeId' when calling getKnowledgeFragmentList";
      }


      var pathParams = {
      };
      var queryParams = {
        'courseStandardId': courseStandardId,
        'knowledgeId': knowledgeId,
        'companyId': opts['companyId']
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = KnowledgeFragmentListVo;
      return this.apiClient.callApi(
        '/api/fragment/knowledge_fragment_list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 收藏列表
     * @param {Integer} courseStandardId 课程标准id
     * @param {Object} opts Optional parameters
     * @param {Integer} opts.starIdLt 上次查询最后一条starId
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/PagingStarFragmentVo}
     */
this.getStarList = function(courseStandardId, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'courseStandardId' is set
      if (courseStandardId == undefined || courseStandardId == null) {
        throw "Missing the required parameter 'courseStandardId' when calling getStarList";
      }


      var pathParams = {
      };
      var queryParams = {
        'courseStandardId': courseStandardId,
        'starIdLt': opts['starIdLt']
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = PagingStarFragmentVo;
      return this.apiClient.callApi(
        '/api/fragment/star', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 创建或者取消收藏
     * @param {module:fragment/model/FragmentStarForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/CreatedVo}
     */
this.putStar = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling putStar";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = CreatedVo;
      return this.apiClient.callApi(
        '/api/fragment/star', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 保存浏览记录
     * @param {module:fragment/model/UserFragmentHistoryForm} body 答题浏览信息
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/CreatedVo}
     */
this.saveHistory = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling saveHistory";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = CreatedVo;
      return this.apiClient.callApi(
        '/api/fragment/history', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 搜索碎片
     * @param {Integer} pageNum 页码
     * @param {Object} opts Optional parameters
     * @param {Integer} opts.majorId 专业id
     * @param {Integer} opts.courseStandardId 课程标准id
     * @param {Integer} opts.knowledgeId 课程目标知识点id
     * @param {String} opts.text 检索词 选填
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/PagingFragmentVo}
     */
this.searchFragment = function(pageNum, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'pageNum' is set
      if (pageNum == undefined || pageNum == null) {
        throw "Missing the required parameter 'pageNum' when calling searchFragment";
      }


      var pathParams = {
      };
      var queryParams = {
        'pageNum': pageNum,
        'majorId': opts['majorId'],
        'courseStandardId': opts['courseStandardId'],
        'knowledgeId': opts['knowledgeId'],
        'text': opts['text']
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = PagingFragmentVo;
      return this.apiClient.callApi(
        '/api/fragment/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }
  };

  export default exports;
