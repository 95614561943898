/**
 * 碎片
 * 碎片API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import CourseStandardDetailVo from '../../fragment/model/CourseStandardDetailVo';
import MajorStatisticsVo from '../../fragment/model/MajorStatisticsVo';
import PagingCourseStandardVo from '../../fragment/model/PagingCourseStandardVo';
  /**
   * CourseStandard service.
   * @module fragment/api/CourseStandardApi
   * @version 0.2.0
   */

  /**
   * Constructs a new CourseStandardApi. 
   * @alias module:fragment/api/CourseStandardApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * 课程标准浏览历史
     * @param {Integer} pageNum 页码
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/PagingCourseStandardVo}
     */
this.browsingHistory = function(pageNum) {
      var postBody = null;

      // verify the required parameter 'pageNum' is set
      if (pageNum == undefined || pageNum == null) {
        throw "Missing the required parameter 'pageNum' when calling browsingHistory";
      }


      var pathParams = {
      };
      var queryParams = {
        'pageNum': pageNum
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = PagingCourseStandardVo;
      return this.apiClient.callApi(
        '/api/course_standard/browsing_history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 获取课程标准对应的思维导图数据
     * @param {Integer} id 课程标准id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/CourseStandardDetailVo}
     */
this.getDetail = function(id) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id == undefined || id == null) {
        throw "Missing the required parameter 'id' when calling getDetail";
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = CourseStandardDetailVo;
      return this.apiClient.callApi(
        '/api/course_standard/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 分专业统计课程标准
     * @param {Integer} pageNum 页码
     * @param {Integer} gradationId 专业层次id 1中专，2大专
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:fragment/model/MajorStatisticsVo>}
     */
this.getMajorStatistics = function(pageNum, gradationId) {
      var postBody = null;

      // verify the required parameter 'pageNum' is set
      if (pageNum == undefined || pageNum == null) {
        throw "Missing the required parameter 'pageNum' when calling getMajorStatistics";
      }

      // verify the required parameter 'gradationId' is set
      if (gradationId == undefined || gradationId == null) {
        throw "Missing the required parameter 'gradationId' when calling getMajorStatistics";
      }


      var pathParams = {
      };
      var queryParams = {
        'pageNum': pageNum,
        'gradationId': gradationId
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = [MajorStatisticsVo];
      return this.apiClient.callApi(
        '/api/course_standard/statistics_by_major', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 搜索课程标准
     * @param {Integer} pageNum 页码
     * @param {Object} opts Optional parameters
     * @param {Integer} opts.gradationId 专业层次id 1中专，2大专
     * @param {Integer} opts.majorId 专业id
     * @param {String} opts.searchText 课程名称
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:fragment/model/PagingCourseStandardVo}
     */
this.search = function(pageNum, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'pageNum' is set
      if (pageNum == undefined || pageNum == null) {
        throw "Missing the required parameter 'pageNum' when calling search";
      }


      var pathParams = {
      };
      var queryParams = {
        'pageNum': pageNum,
        'gradationId': opts['gradationId'],
        'majorId': opts['majorId'],
        'searchText': opts['searchText']
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = PagingCourseStandardVo;
      return this.apiClient.callApi(
        '/api/course_standard/search', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }
  };

  export default exports;
