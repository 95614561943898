<template>
    <div class="main">
        <div class="top-view">
            <div class="k_name">
                {{ p_name }}
            </div>
        </div>
        <video-player class="video-player vjs-custom-skin vjs-big-play-centered"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @timeupdate="onPlayerTimeupdate($event)"
                @ready="playerReadied"
                @ended="onPlayerEnded($event)" v-show="currentFragementDetail != null && currentMediaUrl != null">
        </video-player>
        <div class="back-btn-view" @click="onClickBack" v-if="backNeedShow==true">
            <img src="../assets/back_pre.png" alt="" class="back-img">
        </div>

        <div class="title-view" v-if="currentFragementDetail != null">
            <div class="title-label-view" v-if="currentFragementDetail.title != null">
                <div class="title">{{ currentFragementDetail.title }}</div>
                <div class="sub-title">{{ currentFragementDetail.knowledge.name }}</div>
            </div>
            <!-- <div class="star"></div> -->
        </div>

        <div class="f-content" ref="f_content" :class="{'f-content-expande':expande}">
            <div class="fragment-content" v-if="currentFragementDetail!= null && currentFragementDetail.summary != null">
                {{ currentFragementDetail.summary }}
                
            </div>
    
            <div class="fragment-html-v"  v-if="currentFragementDetail!= null && currentFragementDetail.html != null">
                <p v-html="currentFragementDetail.html"></p>
            </div>
        </div>
        <div class="expande-view" v-if="needShowExpande==true" :class="{'expande-view-expande':!expande}">
            <div class="mask-view">

            </div>
            <div class="btn-view" >
                <div v-if="expande==false" @click="expandeOpen()">收起</div>
                <div v-if="expande==true" @click="expandeClose()">展开更多</div>
            </div>
        </div>
        
        <!-- 本轮：{{ sessionId }}：
        {{ currentCostTime }} -->
        <div class="line-top" :class="{'line-top-150':currentFragementDetail== null}" ></div>


        <div class="unit-list-view" ref="scrollContainer">
            <div v-for="item,subindex in knowledgesListL2" :key="`${item.id}`" class="unit-list-item" :class="[(knowledgeVo != null && `${knowledgeVo.id}` == `${item.id}`)?'unit-item-on':'unit-item']" @click="getKnowledgeDetail(subindex,item.name,item.id)">
                <div :class="[(knowledgeVo != null && `${knowledgeVo.id}` == `${item.id}`)?'level2-title':'level2-title-off']"> {{item.name}}</div>
            </div>
        </div>
       
        <!-- <div class="line-view"></div> -->
        <div  v-if="currentKFList && currentKFList.length > 0" v-for="fragment in currentKFList" :key="fragment.id" style="padding:5px;padding-left:20px;padding-right:20px">
            <div class="knowlage-fragemnt-item" v-if="fragment.type==1" v-on:click="loadFragmentDetail(fragment)">
                <img :src="`${fragment.id}` == `${currentFid}` ? require('../assets/knowledge_video_fragment_on.png') : require('../assets/knowledge_video_fragment_off.png')" alt="" class="icon">
                <div :class="['name',`${fragment.id}` == `${currentFid}`?'name-on':'']" >{{fragment.title}}</div>
                <div class="tag" v-if="fragment.required" >必看</div>
            </div>
            <div class="knowlage-fragemnt-item" v-if="fragment.type==0" v-on:click="goDetail(fragment)">
                            
                <img :src="(`${fragment.id}` == `${currentFid}`) ? require('../assets/knowladge_read_on.png') : require('../assets/knowladge_read_off.png')" alt="" class="icon">
                <div :class="['name',`${fragment.id}` == `${currentFid}`?'name-on':'']" >{{fragment.title}}</div>
                <div class="tag" v-if="fragment.required" >必看</div>
            </div>
        </div>
        <div class="fragment-list-view">
            <div class="knowlage-item" v-for="knowledge,index in knowledgeFragmentList" :key="index">
                <div class="knowlage-name">
                    <img src="../assets/knowladge_l3.png" class="item-title-view-icon" v-if="knowledge.level == 1" v-on:click="loadFragmentDetail(fragment)"/>
                    <img src="../assets/knowladge_l4.png" class="item-title-view-icon4" v-if="knowledge.level == 2" v-on:click="loadFragmentDetail(fragment)"/>
					<div class="level-title">{{knowledge.name}}</div>
                </div>
                <div class="knowlage-fragemnt-list">
                    <div v-for="fragment,findex in knowledge.fragmentList" :key="findex">
                        <div class="knowlage-fragemnt-item" v-if="fragment.type==1" v-on:click="loadFragmentDetail(fragment)">
                            
                            <img :src="(`${fragment.id}` == `${currentFid}`) ? require('../assets/knowledge_video_fragment_on.png') : require('../assets/knowledge_video_fragment_off.png')" alt="" class="icon" >
                            <div :class="['name',`${fragment.id}` == `${currentFid}`?'name-on':'']" >{{fragment.title}}</div>
                            <div class="tag" v-if="fragment.required" >必看</div>
                            <!-- <img :src="(`${fragment.id}` == `${currentFid}`) ? require('../assets/knowladge_read_on.png') : require('../assets/knowladge_read_off.png')" alt="" class="icon-right" v-on:click="goDetail(fragment)"> -->
                        </div>
                        <div class="knowlage-fragemnt-item" v-if="fragment.type==0" v-on:click="goDetail(fragment)">
                            
                            <img :src="(`${fragment.id}` == `${currentFid}`) ? require('../assets/knowladge_read_on.png') : require('../assets/knowladge_read_off.png')" alt="" class="icon" >
                            <div :class="['name',`${fragment.id}` == `${currentFid}`?'name-on':'']">{{fragment.title}}</div>
                            <div class="tag" v-if="fragment.required">必看</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty-view" v-if="currentKFList==null && (knowledgeFragmentList==null || knowledgeFragmentList.length==0)">
            暂无碎片数据
        </div>
        
    </div>
</template>

<script>

import { NavigationDuplicated } from 'vue-router';
import 'video.js/dist/video-js.css'
import FragmentApi from '@/api/src/fragment/api/FragmentApi'
const fragmentApi = new FragmentApi()

import { toTree } from "../util/tree";
import CourseStandardApi from '../api/src/fragment/api/CourseStandardApi'
const courseStandardApi = new CourseStandardApi()
import { Toast } from 'vant';
import { videoPlayer } from 'vue-video-player'
//----  构建请求队列 ------------
const requestQueue = [];
let isProcessing = false;
//-----------------------------
export default {
    components: {
        videoPlayer,
    },
    data() {
        return {
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
                autoplay: false, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4",   // 类型
                    withCredentials: false,
                    src: 'https://fm-cloud-general-prd.oss-cn-shenzhen.aliyuncs.com/fragment/8020202339075678208/碳.mp4' // 测试可用M3U8连接
                }],
                poster:  require("../assets/fragment_cover_img.png"), //你的封面地址
                width: '480px', // 视频框的宽度
                notSupportedMessage: '',
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true
                }
            },
            gk: "",
            courseStandard:null,
            courseStandardId:null,
            knowledgesListL2:null,
            p_name:null,
            knowledgeVo:null,
            knowledgeId:null,
            knowledgeFragmentList:null,
            currentKFList:[],
            currentFid:null,
            currentFragementDetail:null,
            currentMediaUrl:null,
            currentFragementStarId:null,
            expande:false,
            needShowExpande:false,
            time: 0,
            timer: null,
            isPlaying:false,
            report_time_queue:[],
            tempTimeLabel:"",
            postStatisticsData:{},
            query_fid:-1,
            query_subIndex:0,
            backNeedShow:false,
            sessionId:null,
            lastFlagTime:-1,
            currentCostTime:0
        }
    },
    computed: {
        player() {
            return this.$refs.videoPlayer.player
        }
    },
    watch: {
        gk: function () {
        }
    },
    mounted() {
        // this.courseStandardId =  this.$route.query.id;
        // console.log(`id-----> ${this.courseStandardId}`)
        var knowledgeParams = this.$route.params
        var queryParams = this.$route.query
        console.log("knowledgeParams==========")
        console.log(knowledgeParams)

        
        if (Object.keys(queryParams).length === 0) {
            console.log('queryParams 是空对象');
        } else {
            knowledgeParams = queryParams;
        }
        
        this.courseStandardId = knowledgeParams.courseStandardId
        this.p_name = knowledgeParams.knowledgeName
        const index = knowledgeParams.index
        const subIndex = knowledgeParams.subIndex
        this.query_subIndex = subIndex
        this.query_fid = knowledgeParams.fid
        this.backNeedShow = (this.query_fid != -1)
        this.fetchCourseStandData(index,this.query_subIndex,knowledgeParams)
        console.log(this.p_name)
        
        let that = this
        document.addEventListener("keydown", function (e) {
            if (e.keyCode === 39) {
                that.goFast()
            }
            if (e.keyCode === 37) {
                that.goBack()
        }
        })
        // this.fetchData()
    },
    onUnmounted(){
        console.log('=====onUnmounted======');
        console.log()
        var emptyFlag = (Object.keys(this.postStatisticsData).length === 0 && this.postStatisticsData.constructor === Object)
        if(emptyFlag != true){
            this.lastFlagTime = -1
            this.addStatisticsQueue()
        }
    },
    beforeDestroy(){
        // clearInterval(this.timer);
    },
    methods: {
        onClickBack(){
            var courseStandardId = this.courseStandardId
            this.$router.replace({name: 'knowledgeMap', query: {courseStandardId}})
        },
        fetchCourseStandData(index,subIndex,knowledgeParams) {
            var courseStandardId = this.courseStandardId
            courseStandardApi.getDetail(courseStandardId).then(resp => {
                let tree = toTree(resp.courseTarget.knowledgeMap)
                var knowledgeTree = tree
                var knowledge = knowledgeTree[index]
                this.knowledgesListL2 = knowledge.children
                this.$forceUpdate()
                this.scrollToMiddle(index,subIndex)
                if (this.knowledgesListL2.length > 0){
                    this.knowledgeId = knowledgeParams.knowledgeId
                    this.getKnowledgeDetail(subIndex,knowledgeParams.knowledgeName,this.knowledgeId)
                }
            })
        },
        scrollToMiddle(index,subIndex) {
            // 获取滚动容器的DOM元素
            const container = this.$refs.scrollContainer;
            // 获取第五个滚动项的DOM元素
            // const fifthItem = container.querySelectorAll('.unit-list-item');
            this.$nextTick(() => {
                const fifthItem = this.$refs.scrollContainer.querySelectorAll('.unit-list-item')[subIndex];
                console.log(fifthItem);
                console.log('----开始滚动----')
                fifthItem.scrollIntoView({ block: 'center' });
            });
            
        },
        getKnowledgeDetail(subindex,name,item){
            this.p_name = name
            this.query_subIndex = subindex
            let courseStandardId = this.courseStandardId
            let knowledgeId =  item
            fragmentApi.getKnowledgeFragmentList(courseStandardId, knowledgeId).then(resp => {
            
                this.knowledgeVo = resp
                
                this.knowledgeId = resp.id
                this.currentKFList = this.knowledgeVo.fragmentList
                this.knowledgeFragmentList = resp.subKnowledgeList
                
                
                if(this.query_fid != -1){
                    this.loadFragmentDetailById(this.query_fid)
                }
            }).catch(e => {
                console.log("getKnowledgeFragmentList====》"+e)
            //   this.loading = false
            //   this.error = true
            })
        },
        
        loadFragmentDetail(fragment){
            this.currentFragementDetail = null
            this.currentFragementStarId = null

            var temp_id = fragment.id
            if (temp_id == null) {
                temp_id = this.fragmentId
            }
            var courseStandardId = this.courseStandardId
            let query = this.$router.history.current.params;
            let newQuery = JSON.parse(JSON.stringify(query));
            newQuery.fid = temp_id+""
            newQuery.subIndex = this.query_subIndex
            newQuery.knowledgeId = this.knowledgeVo.id

            if (JSON.stringify(query) !== JSON.stringify(newQuery)) {
                this.$router.replace({ query: newQuery }).catch(err => {
                if (err.name === 'NavigationDuplicated') {
                    // 忽略重复导航错误 
                    return;
                }
                // 处理其他错误
                throw err;
            });

            //fragmentView 分享
            // window.fragmentViewForwardUrl = () => {
                

            // }

            
            // this.$router.replace({ query: newQuery})
            // console.log(temp_id)
            fragmentApi.getFragment(temp_id,{courseStandardId}).then(resp => {
                this.setMainData(resp)
            })
            }
        },

        loadFragmentDetailById(fid){
            this.currentFragementDetail = null
            this.currentFragementStarId = null

            var temp_id = fid
            // if (temp_id == null) {
            // 	temp_id = this.fragmentId
            // }
            var courseStandardId = this.courseStandardId
            
            fragmentApi.getFragment(temp_id,{courseStandardId}).then(resp => {
                console.log("resp=====》")
                console.log(resp)
                this.setMainData(resp)
                if(this.query_fid != -1){
                    this.query_fid = -1
                }
            })
        },

        setMainData(dataParse){
        
            this.currentFragementDetail = dataParse
            if (this.currentFragementDetail.starId && this.currentFragementDetail.starId != null){
                this.currentFragementStarId =  this.currentFragementDetail.starId
            }
            
            console.log("this.currentFragementStarId--->");
            console.log(this.currentFragementStarId);
            if (dataParse.resList.length>0 && dataParse.resList[0] != undefined){
                
                var resArr = dataParse.resList[0]

                console.log(resArr);
                this.player.pause()
                this.currentMediaUrl = resArr.mediaUrl
                this.currentFid = dataParse.id.toString()
                this.playerOptions.src = this.currentMediaUrl
                this.player.src(this.currentMediaUrl)
                this.player.play()
                this.sessionId = null
                this.postStatisticsData.sessionId = null
                this.lastFlagTime = -1
                //----------------- 先传一个空的 ---------------
                this.resetPostStatisticsData()
                this.postStatisticsData.dottingTimeList = []
                this.addStatisticsNullQueue()
                //--------------------------------------------
                                
            }else{
                this.player.pause()
                this.currentFid = dataParse.id.toString()
                this.currentMediaUrl = null
                Toast('该碎片播放地址有误，请检查碎片资源');
            }	
            
            this.$nextTick(() => {
                let lineHeight = 25
                if (this.$refs.f_content.offsetHeight > lineHeight * 4) {
                    this.expande = false
                    this.needShowExpande = true
                } else {
                    this.expande = true
                    this.needShowExpande = false
                }
                this.expandeOpen()
            })
        },
        goDetail(item) {
            
            var fragmentId = item.id
            var courseStandardId = this.courseStandardId
            var knowledgeId = item.knowledgeId
            var starId = item.starId
            var detailParamer = {fragmentId,courseStandardId,knowledgeId,starId}
            
            this.$router.push({name: 'fragmentDetail',query: detailParamer})
        },

        expandeOpen(){
            this.expande = true
        },

        expandeClose(){
            this.expande = false
        },
        
        onPlayerPlay(player) {
            // 播放回调
            console.log("当前视频总时长------")
            console.log(player.cache_.duration)
            this.isPlaying = true
        },
        onPlayerPause(player) {
            // 暂停回调
            console.log("当前视频暂停------")
            this.isPlaying = false
            var emptyFlag = (Object.keys(this.postStatisticsData).length === 0 && this.postStatisticsData.constructor === Object)
            if(emptyFlag != true){
                this.lastFlagTime = -1
                this.addStatisticsQueue()
                this.resetPostStatisticsData()
                this.report_time_queue = []
            }
            
        },
        onPlayerEnded($event) {
            //播放完成回调
            console.log("当前播放完成------")
            this.isPlaying = false
            this.addStatisticsQueue()
            this.resetPostStatisticsData()
            this.report_time_queue = []
        },
        goFast() {
            //快进
            this.gk += 5;
            // this.$refs.videoPlayer.player.currentTime(this.gk)
        },
        goBack() {
            //后退
            this.gk -= 5;
            // this.$refs.videoPlayer.player.currentTime(this.gk);
        },
        onPlayerTimeupdate(player) {
            // console.log('----------player.cache_.currentTime------------')
            // console.log(player.cache_.currentTime)
            var timeFlag = Math.trunc(this.player.cache_.currentTime);
            if (timeFlag % 5 === 0 && this.lastFlagTime != timeFlag) {//每五秒要上传
                if (!this.report_time_queue.includes(timeFlag)) {
                    
                    this.lastFlagTime = timeFlag
                    this.report_time_queue.push(timeFlag);
                    
                }
                console.log(this.report_time_queue)
                this.postStatisticsData.dottingTimeList = this.report_time_queue
                console.log('开始上报-------')
                this.addStatisticsQueue()
                this.resetPostStatisticsData()
                this.report_time_queue = []
            }else{
                
                if (!this.report_time_queue.includes(timeFlag) && this.lastFlagTime != timeFlag) {
                    this.report_time_queue.push(timeFlag);
                }
                this.postStatisticsData.dottingTimeList = this.report_time_queue
            }

            // this.tempTimeLabel +=  player.cache_.currentTime + "|"
            // console.log('--------------------------------')
            //获取当前播放时间
            // this.gk = player.cache_.currentTime;
        },
        playerReadied(player) {
            // this.lastFlagTime = -1
            // //----------------- 先传一个空的 ---------------
            // this.resetPostStatisticsData()
            // this.postStatisticsData.dottingTimeList = []
            // this.addStatisticsNullQueue()
            //--------------------------------------------
        },
        /*
        startTimer(){
            this.timer = setInterval(() => {
                if(this.isPlaying==false){
                    // clearInterval(this.timer);
                    console.log('没有播放，忽略--------')
                }else{
                    this.time++;
                    console.log('正在播放--------')
                    if (this.time % 5 === 0) {
                        //信息上报
                        var timeFlag = Math.trunc(this.player.cache_.currentTime);
                        this.report_time_queue.push(timeFlag)
                        console.log(this.report_time_queue)
                        this.postStatisticsData.dottingTimeList = this.report_time_queue
                        console.log('开始上报-------')
                        this.addStatisticsQueue()
                        this.resetPostStatisticsData()
                        this.report_time_queue = []
                    }else{
                        // console.log('----this.player.cache_.currentTime-------')
                        //console.log(this.player.cache_.currentTime)
                        var timeFlag = Math.trunc(this.player.cache_.currentTime);
                        this.report_time_queue.push(timeFlag)
                        this.postStatisticsData.dottingTimeList = this.report_time_queue
                    }
                }
                
            }, 1000);
        },*/


        //---------------- 请求队列相关 -------------

        addStatisticsQueue(){
            
            var that = this
           
            that.postStatisticsData.sessionId = that.sessionId
            that.addRequest(() => that.statisticsUpload.call())
                        .then(response => console.log('Response:', response))
                        .catch(error => console.error('Error:', error));
        },

        addStatisticsNullQueue(){

            var that = this
            that.resetPostStatisticsData()
            that.postStatisticsData.dottingTimeList = [] 
            delete that.postStatisticsData.sessionId
            that.addRequest(() => that.statisticsUpload.call())
                    .then(response => console.log('Response:', response))
                    .catch(error => console.error('Error:', error));
        },

        addRequest(requestFunction) {
            var that = this
            return new Promise((resolve, reject) => {
                requestQueue.push({ requestFunction, resolve, reject });
                that.processQueue();
            });
        },
        

        async processQueue() {
            if (isProcessing) return;
            isProcessing = true;

            while (requestQueue.length > 0) {
                const { requestFunction, resolve, reject } = requestQueue.shift();
                try {
                const result = await requestFunction();
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            }

            isProcessing = false;
        },


        resetPostStatisticsData(){
            this.postStatisticsData = {}
            this.postStatisticsData.fragmentId = this.currentFid
            this.postStatisticsData.knowledgeId = this.knowledgeId
            this.postStatisticsData.courseStandardId = this.courseStandardId
        },

        statisticsUpload(){

            if(!this.postStatisticsData.dottingTimeList){
                return
            }

           
            this.postStatisticsData.sessionId = this.sessionId

            if(this.postStatisticsData.dottingTimeList.length == 1 && this.postStatisticsData.dottingTimeList[0]==0){
                
                this.postStatisticsData.dottingTimeList = []
                this.postStatisticsData.sessionId = null
            }
            
            var that = this
            // 定义要发送的数据

            const postStatisticsData = this.postStatisticsData;
            console.log('apiClient postStatisticsData', postStatisticsData)
            let token = localStorage.getItem('Authorization')
            console.log('apiClient token', token)
            var headers = {}
            if(token && token != 'undefined') {
                headers = {
                    'Authentication': token // 将 your_token_here 替换为您的实际认证令牌
                };
            }else{
                const Authorization  = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4MDEyMDE4Njk5MzY5MzczNjk2IiwiYXVkIjpbIjEiLCI4Il0sIm5iZiI6MTcxMjM4NzEwOSwiaXNzIjoiRURVLTEiLCJleHAiOjE3NDM0OTExMDksImlhdCI6MTcxMjM4NzEwOSwianRpIjoiYjllN2Q1OGQtNGY4Zi00M2VkLTk4ODQtNmU5YzIxOTNlN2VhIn0.5qzGu7qFBIuRvvHGJA0TF10r1MsTKZDvChTDZhVhGmY';
                headers = {
                    'Authentication': Authorization // 将 your_token_here 替换为您的实际认证令牌
                };
            }
            // 定义要添加的 Authentication 头字段
            

            // 发送 POST 请求，将 headers 参数传递给 fetch 的第二个参数
            fetch('https://fragmentory-dev-fragmentory-dev-imxpolwnhm.cn-shenzhen.fcapp.run', {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json' // 设置 Content-Type 为 application/json
            },
            body: JSON.stringify(postStatisticsData)
            })
            .then(response => {
                if (!response.ok) {
                   
                    const error = new Error('Network response was not ok');
                    error.status = response.status;
                    error.statusText = response.statusText;
                    throw error;
                }
                return response.json();
            })
            .then(data => {
                // Toast('成功发送请求:'+data);
                that.sessionId = data.sessionId
                that.currentCostTime = data.costTime
                console.log('成功发送请求:', that.sessionId);
            })
            .catch(error => {

                if(error.status == 400){
                   
                    that.sessionId = null
                    that.player.pause()
                }
            });
        }
    },
}
</script>

<style lang="less" scoped>
    .main{
        display: flex;
        flex-direction: column;
    }

    .top-view{
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        height: 214px;
        background-image: url('../assets/fragment_cover_img.png');
        background-size: cover; /* 控制背景图片大小 */
        background-position: center; /* 控制背景图片位置 */
        margin: 0 auto; /* 使内容水平居中 */
        max-width: 480px;
        align-items: center;
        justify-content: center;
        .k_name{
            margin: 60px;
            max-height: 234px;
            overflow: hidden;
            color: white;
            font-size: 20px;
        }
    }

    .video-player{
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        height: 214px;
        max-height: 214px;;
        margin: 0 auto; /* 使内容水平居中 */
        max-width: 480px;
        :deep(.vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode)),:deep(.vjs-fluid:not(.vjs-audio-only-mode)) {
            padding-top: 214px;
          }        
    }

    .title-view{
        display: flex;
        flex-direction: row;
        margin-top: 244px;
        margin-left: 15px;
        margin-right: 15px;
        .title-label-view{
            display: flex;
            flex-direction: column;
            .title{
                font-weight: 500;
                font-size: 20px;
                color: #000000;
            }

            .sub-title{
                font-weight: 400;
                font-size: 10px;
                color: #FF8826;
                margin-top: 10px;
            }

        }
        .star{
            height: 30px;
            width: 30px;
            margin-left: auto;
            background-color: #FF8826;
        }
    }

    .fragment-content{
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 11px;
        color: #000000;
        line-height: 20px;
        margin: 15px;
    }

    .unit-list-view{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        padding: 15px;
        font-size: 14px;
       
        overflow-x: auto; /* 允许横向滚动 */
        .unit-item{
            width: 136px;
            height: 56px;
            background: #F6F6F6;
            border-radius: 4px;
            text-align: center;
            padding: 5px;
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            flex: 0 0 auto; /* 确保所有项目都具有相同的大小 */
            justify-content: center;
        }
        .unit-item-on{
            width: 136px;
            height: 56px;
            background: #FFF4EB;
            border-radius: 4px;
            text-align: center;
            padding: 5px;
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            flex: 0 0 auto; /* 确保所有项目都具有相同的大小 */
            justify-content: center;
        }

        .level2-title {
            color: #FF8826;
            overflow: hidden;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
        
        .level2-title-off {
            color: #333333;
            overflow: hidden;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }
    .line-view{
        
        height: 13px;
        background-image: url('../assets/bg_line.png');
        background-size: cover; /* 控制背景图片大小 */
        background-position: center; /* 控制背景图片位置 */
    }

    .line-top{
        background-color: #e7e7e7;
        height: 4px;
        width: 100%;
    }

    .line-top-150{
        margin-top: 234px;
    }

    .fragment-html-v{
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .fragment-list-view{
        display: flex;
        flex-direction: column;
        .knowlage-name{
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            height: 22px;
            margin-left: 20px;
            margin-top: 20px;
            margin-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .item-title-view-icon {
                width: 15px;
                height: 15px;
                margin-left: 5px;
                margin-right: 5px;
            }
            .item-title-view-icon4 {
                width: 30px;
                height: 15px;
                margin-left: 5px;
                margin-right: 5px;
            }
            .level-title {
                color: #333333;
                font-size: 16px;
                font-weight: bold;
            }
        }
        .knowlage-item{
            display: flex;
            flex-direction: column;
            .knowlage-fragemnt-list{
                display: flex;
                flex-direction: column;
                background: rgba(255,255,255,0.39);
                box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.03);
                border-radius: 6px;
                margin: 20px;
                margin-bottom: 0px;
            }
        }
    }
    .knowlage-fragemnt-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid rgb(226, 226, 226);
        .icon{
            width: 19px;
            height: 19px;
        }
        .name{
            font-weight: 500;
            font-size: 15px;
            color: #666666;
            margin-left: 10px;
        }
        .name-on{
            font-weight: 500;
            font-size: 15px;
            color: #FF8826;
            margin-left: 10px;
        }
        .tag{
            font-weight: 500;
            font-size: 10px;
            color: #FF8826;
            margin-left: 10px;
        }
        .icon-right{
            width: 19px;
            height: 19px;
            margin-left: auto;
        }
    }

    .vjs-big-play-button{
        font-size: 2.5em;
        line-height: 2.3em;
        height: 2.5em;
        width: 2.5em;
        -webkit-border-radius: 2.5em;
        -moz-border-radius: 2.5em;
        border-radius: 2.5em;
        background-color: #73859f;
        background-color: rgba(115,133,159,.5);
        border-width: 0.15em;
        margin-top: -1.25em;
        margin-left: -1.75em;
    }

    .expande-view{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: -40px;
        .mask-view{
            background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
            height: 20px;
        }
        .btn-view{
            display: flex;
            flex-direction: column;
            color: #FF8826;
            font-size: 15px;
            align-items: center;
            padding-bottom: 10px;
            background: white;
        }
        
    }
    .expande-view-expande{
        margin-top: 0px;
    }
    .f-content-expande{
        height: 80px;
        overflow: hidden;
    }

    .empty-view{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        color: #a5a5a5;
    }

    .back-btn-view{
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 10px;
        left: 10px;
        background: #73859f62;
        width: 50px;
        height: 30px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin-left: 10px;
        .back-img{
            width: 20px;
            height: 20px;
        }
    }
</style>