<template>
  <div class="home">
    <div class="major_card">
      <div class="major" @click="goSelectMajor">
        <div>
          <div style="font-weight: bold;font-size: 20px;">{{major.id ? major.name : '选择专业'}}</div>
          <div style="font-size: 12px;color: #666666">{{major.companyName}}</div>
        </div>
        <div class="arrow">
          <img mode="aspectFit" src="/images/arrow_right.png" style="width: 15px;height: 15px">
        </div>
      </div>
      <van-search placeholder="请输入搜索关键词" @click="goFragmentSearch"/>
    </div>
    <div class="course_container">
      <div class="header">
        <div style="font-weight: bold;font-size: 18px;">
          专业课程
        </div>
      </div>
      <div class="container">
        <van-list
          :immediate-check="false"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad">
          <div class="course_item" v-for="courseStandard in courseList" :key="courseStandard.id.toString()" @click="() => goKnowledgeDetail(courseStandard.id)">
            <div style="width: 25%;display: flex;align-items: center;">
              <img :src="courseStandard.thumbUrl || `https://fm-cloud-general-prd.oss-cn-shenzhen.aliyuncs.com/img/course_standard/default/default_${courseStandard.id.toString().substr(-1)}.png`"/>
            </div>
            <div style="width: 75%;" class="info">
              <div style="font-weight: bold;font-size:18px">
                <span v-if="courseStandard.awarded" style="font-size: 12px;color: #fbbb00;margin-right: 5px;border: 1px solid;padding-left: 2px;padding-right: 2px;vertical-align: middle;">精</span>
                <span style="vertical-align: middle;">{{courseStandard.name}}</span>
              </div>
              <div class="summary">{{courseStandard.summary}}</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import clone from 'clone'
import { mapState } from 'vuex'
import DictApi from '@/api/src/dict/api/DictApi.js'
import FragmentApi from '@/api/src/fragment/api/FragmentApi'
import CourseStandardApi from '@/api/src/fragment/api/CourseStandardApi'
const fragmentApi = new FragmentApi()
const dictApi = new DictApi()
const courseStandardApi = new CourseStandardApi();
export default {
  name: 'CourseList',
  data() {
    return {
      gradationList: [],
      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
      totalCourse: 0,
      major: {
        id: undefined,
        name: '',
        companyName: '',
        fragmentCount: 0
      },
      courseList: [],
	    majorId:undefined
    }
  },
  async mounted() {
    console.log('mounted')
    let resp = await dictApi.getGradation();
    console.log(resp)
    this.gradationList = resp

    let majorId = this.$route.params.majorId
  	if(!majorId){
  	  return
  	}
	  this.majorId = majorId
  	await this.getData()
    console.log('onLoad')
  	await this.onLoad(1)
  },
  methods: {
    async getData() {
      let majorId = this.majorId
      console.log(majorId)
      let resp = await fragmentApi.getFragmentStatisticsByMajor(majorId)
      this.major = resp

      document.title = this.major.name;
      jsBridge.postNotification('CLIENT_TOOLBAR_TITLE', {'webTitle': this.major.name});
    },
    async onLoad(page) {
      page = page || this.pageNum + 1

      let resp = await courseStandardApi.search(page, {
        majorId: this.majorId
      })

      if(!resp.hasNextPage){
        this.finished = true
      }else{  
        this.finished = false
        this.pageNum = page
      }

      this.totalCourse = resp.total
      if(page == 1) {
        this.courseList = resp.list
      }else{
        this.courseList = this.courseList.concat(resp.list)
      }
      this.loading = false
    },
    goFragmentSearch() {
      // let {majorId} = this.$route.query
      let majorId = this.majorId
      this.$router.push({name: 'fragmentSearch', params: {majorId}})
    },
    goSelectMajor() {
      this.$router.push({name: 'major'})
    },
    goKnowledgeDetail(courseStandardId) {
      this.$router.push({name: 'knowledgeMap', query: {courseStandardId}})
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .major_card {
    // border-radius: 5px;
    // box-shadow: 0px 2px 6px 0px #cccccc;
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px 20px;
    justify-content: space-around;
    .major {
      display: flex;
      justify-content: space-between;
      .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .statistics_card_container {
    margin-top: 10px;
    display: flex;
    >:first-child{
      margin-right: 5px;
    }
    >:last-child{
      margin-left: 5px;
    }
    .statistics_card {
      width: 50%;
      border-radius: 5px;
      box-shadow: 0px 2px 6px 0px #cccccc;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 110px;
      .number {
        font-size: 30px;
        font-weight: bold;
      }
      .label {
        font-size: 12px;
      }
    }
  }
  .line {
    height: 1px;
    background: #66666626;
    border-radius: 10px;
  }
  .course_container {
    border-radius: 5px;
    box-shadow: 0px 2px 6px 0px #cccccc;
    padding: 10px;
    .header {
      display: flex;
      justify-content: space-between;
    }
    .container {
      margin-top: 20px;
	  .scroll-container {
		  height: calc(100vh - 340rpx);
	  }
      .course_item {
        display: flex;
        // border-radius: 5px;
        // box-shadow: 3px 3px 3px 0px #cccccc;
        margin-top: 20px;
        img{
          width: 100%;
        }
        .info {
          padding: 0px 0 0px 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .summary {
            font-size:12px;
            color:#666666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            min-height: 68px;
          }
        }
      }
    }
  }
  .category_tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 36px;
    line-height: 36px;
    padding-bottom: 5px;
    padding-top: 5px;
    .category_item {
      flex: 1;
      background: #ccc;
      // border-radius: 1rem;
      height: 36px;
      line-height: 36px;
      text-align: center;
      &:first-child {
        border-radius: 1rem 0 0 1rem;
      }
      &:last-child {
        border-radius: 0 1rem 1rem 0;
      }
    }
    .active {
      background: #8B335D;
      color: white;
    }
  }
  .search {
	  margin-top: 20rpx;
	  margin-bottom: 20rpx;
    background: #F6F8FA;
    height: 32px;
    font-size: 18px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20rpx;
    padding-right: 20rpx;
    border-radius: 5px;
    input {
      width: 100%;
      border: 0;
      background: #ffffff00;
      height: 32px;
      font-size:12px;
      padding-left: 25px;
      outline: none;
    }
    input:focus {
      border: 0;
    }
  }
  .fragment_list {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .not_found {
      text-align: center;
      color: #888888;
      margin-top: 20px;
    }
    .fragment_item {
      display: flex;
      margin-bottom: 10px;
      border-bottom: 1px solid #ececec;
      padding-bottom: 10px;
      height: 43px;
      .thumb {
        display: flex;
        align-items: center;
        margin-right: 10px;
        div {
          background: #f1a542;
          height: 43px;
          width: 43px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        padding-right: 4px;
        justify-content: space-between;
        .title {
          text-align: left;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100vw - 80px);
          .tag {
            padding-right: 6px;
            border-radius: 1rem;
            font-size: 14px;
            font-weight: normal;
            img {
              height: 14px;
            }
          }
        }
        .course {
          font-size: 12px;
          color: #565656;
        }
        .summary {
          text-align: left;
          font-size: 12px;
        }
        .knowedge {
          text-align: left;
          font-size: 12px;
          color: #777777;
          margin-top: 6px;
          span {
            background: #f1a542;
            border-radius: 3px;
            padding-left: 10px;
            padding-right: 10px;
            color: white;
          }
        }
      }
      img {
        height: 50px;
      }
    }
    .active {
      background: #8B335D;
      color: white;
    }
  }
  .moreBtn {
    // position: fixed;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #8B335D;
    border: 0;
    color: white;
    width: 100%;
    bottom: 0;
    height: 40px;
    margin-top: 20px;
  }
  .no_more {
    text-align: center;
    color: #888888;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .moreBtn:focus {
    outline: none;
  }
  .moreBtn:active {
    background: #bf4780;
  }
}
</style>
  