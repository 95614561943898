export default {
  chaoxingCompanyList: [{
  //   name: '四川西南航空职业学院',
  //   appId: '66c1e8c3d16344b68910de303445045a',
  //   appKey: '3N4Gu7Ks83WID2TO',
  //   state: '177372'
  // },{
    name: '成都校区',
    appId: 'f32795d83e5e440b86f8ea868b289d6f',
    appKey: '8c8vQA5sI2mvaQGv',
    state: '190729'
  },{
    name: '绵阳校区',
    appId: '6690a5bde5ec4402a574a6f25941ba2f',
    appKey: '36MzfQM0Mv45FI8y',
    state: '177365'
  },{
    name: '宿州校区',
    appId: 'a4c6253811be4099b462f98e0ad5cb2a',
    appKey: 'Zeap3t80b2HH823n',
    state: '177370'
  },{
    name: '青岛校区',
    appId: '141fd04775034d59a1c1e07c585b343f',
    appKey: 'dxiN4WC0244O87Cs',
    state: '177368'
  }]
}