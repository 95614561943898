/**
 * 数据字典api
 * 数据字典api
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import CompanySummaryVo from '../../dict/model/CompanySummaryVo';
import CompanyVo from '../../dict/model/CompanyVo';
import GradationVo from '../../dict/model/GradationVo';
  /**
   * Dict service.
   * @module dict/api/DictApi
   * @version 0.2.0
   */

  /**
   * Constructs a new DictApi. 
   * @alias module:dict/api/DictApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * 获取当前登录用户可用的学校学期字典
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:dict/model/CompanyVo>}
     */
this.getAvailableSchoolTerm = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = [CompanyVo];
      return this.apiClient.callApi(
        '/api/dict/available_school_term', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 获取数据字典
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:dict/model/CompanySummaryVo>}
     */
this.getCompany = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = [CompanySummaryVo];
      return this.apiClient.callApi(
        '/api/dict/company', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 获取数据字典
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:dict/model/CompanySummaryVo>}
     */
this.getCompanyMajor = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = [CompanySummaryVo];
      return this.apiClient.callApi(
        '/api/dict/company_major', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 获取数据字典
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:dict/model/GradationVo>}
     */
this.getGradation = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = [GradationVo];
      return this.apiClient.callApi(
        '/api/dict/gradation', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 获取随机id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<'Integer'>}
     */
this.getRandomId = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = ['Integer'];
      return this.apiClient.callApi(
        '/api/dict/random_id', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }
  };

  export default exports;
