/**
 * 用户接口
 * 用户接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The ImproveInfoForm model module.
   * @module account/model/ImproveInfoForm
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>ImproveInfoForm</code>.
   * @alias module:account/model/ImproveInfoForm
   * @class
   * @param primaryCompanyId {Integer} 最新组织关系所在单位
   * @param idNumber {String} 身份证号
   */
  var exports = function(primaryCompanyId, idNumber) {
    var _this = this;

    _this['primaryCompanyId'] = primaryCompanyId;
    _this['idNumber'] = idNumber;

  };

  /**
   * Constructs a <code>ImproveInfoForm</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:account/model/ImproveInfoForm} obj Optional instance to populate.
   * @return {module:account/model/ImproveInfoForm} The populated <code>ImproveInfoForm</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'primaryCompanyId')) {
        obj['primaryCompanyId'] = ApiClient.convertToType(data['primaryCompanyId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'idNumber')) {
        obj['idNumber'] = ApiClient.convertToType(data['idNumber'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'majorId')) {
        obj['majorId'] = ApiClient.convertToType(data['majorId'], 'Integer');
      }
    }
    return obj;
  }

  /**
   * 最新组织关系所在单位
   * @member {Integer} primaryCompanyId
   */
  exports.prototype['primaryCompanyId'] = undefined;
  /**
   * 身份证号
   * @member {String} idNumber
   */
  exports.prototype['idNumber'] = undefined;
  /**
   * 学生需要填专业Id
   * @member {Integer} majorId
   */
  exports.prototype['majorId'] = undefined;



  export default exports;
;


