/**
 * 用户接口
 * 用户接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The UserSearchForm model module.
   * @module account/model/UserSearchForm
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>UserSearchForm</code>.
   * @alias module:account/model/UserSearchForm
   * @class
   * @param idNumberList {Array.<String>} 身份证号列表
   */
  var exports = function(idNumberList) {
    var _this = this;

    _this['idNumberList'] = idNumberList;
  };

  /**
   * Constructs a <code>UserSearchForm</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:account/model/UserSearchForm} obj Optional instance to populate.
   * @return {module:account/model/UserSearchForm} The populated <code>UserSearchForm</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'idNumberList')) {
        obj['idNumberList'] = ApiClient.convertToType(data['idNumberList'], ['String']);
      }
    }
    return obj;
  }

  /**
   * 身份证号列表
   * @member {Array.<String>} idNumberList
   */
  exports.prototype['idNumberList'] = undefined;



  export default exports;
;


