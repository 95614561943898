/**
 * 碎片
 * 碎片API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The KnowledgeVo model module.
   * @module fragment/model/KnowledgeVo
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>KnowledgeVo</code>.
   * @alias module:fragment/model/KnowledgeVo
   * @class
   */
  var exports = function() {
    var _this = this;











  };

  /**
   * Constructs a <code>KnowledgeVo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:fragment/model/KnowledgeVo} obj Optional instance to populate.
   * @return {module:fragment/model/KnowledgeVo} The populated <code>KnowledgeVo</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'pid')) {
        obj['pid'] = ApiClient.convertToType(data['pid'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'sortKey')) {
        obj['sortKey'] = ApiClient.convertToType(data['sortKey'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'count')) {
        obj['count'] = ApiClient.convertToType(data['count'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'drillCount')) {
        obj['drillCount'] = ApiClient.convertToType(data['drillCount'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'fragmentCount')) {
        obj['fragmentCount'] = ApiClient.convertToType(data['fragmentCount'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'keyPoint')) {
        obj['keyPoint'] = ApiClient.convertToType(data['keyPoint'], 'Boolean');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'difficultPoint')) {
        obj['difficultPoint'] = ApiClient.convertToType(data['difficultPoint'], 'Boolean');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'priority')) {
        obj['priority'] = ApiClient.convertToType(data['priority'], 'Integer');
      }
    }
    return obj;
  }

  /**
   * id
   * @member {Integer} id
   */
  exports.prototype['id'] = undefined;
  /**
   * id
   * @member {Integer} pid
   */
  exports.prototype['pid'] = undefined;
  /**
   * 序号
   * @member {Integer} sortKey
   */
  exports.prototype['sortKey'] = undefined;
  /**
   * 名称
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * 碎片条数
   * @member {Integer} count
   */
  exports.prototype['count'] = undefined;
  /**
   * 练习题数
   * @member {Integer} drillCount
   */
  exports.prototype['drillCount'] = undefined;
  /**
   * 碎片条数
   * @member {Integer} fragmentCount
   */
  exports.prototype['fragmentCount'] = undefined;
  /**
   * 是否是重点
   * @member {Boolean} keyPoint
   */
  exports.prototype['keyPoint'] = undefined;
  /**
   * 是否是难点
   * @member {Boolean} difficultPoint
   */
  exports.prototype['difficultPoint'] = undefined;
  /**
   * 优先级
   * @member {Integer} priority
   */
  exports.prototype['priority'] = undefined;



  export default exports;
;


