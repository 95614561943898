/**
 * 用户接口
 * 用户接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The ResetPhoneNumPwdForm model module.
   * @module token/model/ResetPhoneNumPwdForm
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>ResetPhoneNumPwdForm</code>.
   * @alias module:token/model/ResetPhoneNumPwdForm
   * @class
   * @param companyId {Integer} companyId
   * @param name {String} 姓名
   * @param idNumber {String} 身份证号，若返回为空，则跳转到完善个人信息页面
   * @param password {String} 密码
   * @param verifyToken {String} token 用于对比手机号
   * @param accountId {Integer} accountId
   */
  var exports = function(companyId, name, idNumber, password, verifyToken, accountId) {
    var _this = this;

    _this['companyId'] = companyId;
    _this['name'] = name;
    _this['idNumber'] = idNumber;
    _this['password'] = password;

    _this['verifyToken'] = verifyToken;

    _this['accountId'] = accountId;
  };

  /**
   * Constructs a <code>ResetPhoneNumPwdForm</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:token/model/ResetPhoneNumPwdForm} obj Optional instance to populate.
   * @return {module:token/model/ResetPhoneNumPwdForm} The populated <code>ResetPhoneNumPwdForm</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'companyId')) {
        obj['companyId'] = ApiClient.convertToType(data['companyId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'idNumber')) {
        obj['idNumber'] = ApiClient.convertToType(data['idNumber'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'password')) {
        obj['password'] = ApiClient.convertToType(data['password'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'verifyResult')) {
        obj['verifyResult'] = ApiClient.convertToType(data['verifyResult'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'verifyToken')) {
        obj['verifyToken'] = ApiClient.convertToType(data['verifyToken'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'phoneNum')) {
        obj['phoneNum'] = ApiClient.convertToType(data['phoneNum'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'accountId')) {
        obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Integer');
      }
    }
    return obj;
  }

  /**
   * companyId
   * @member {Integer} companyId
   */
  exports.prototype['companyId'] = undefined;
  /**
   * 姓名
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * 身份证号，若返回为空，则跳转到完善个人信息页面
   * @member {String} idNumber
   */
  exports.prototype['idNumber'] = undefined;
  /**
   * 密码
   * @member {String} password
   */
  exports.prototype['password'] = undefined;
  /**
   * 认证结果，认证成功：PASS，认证失败：UNKNOWN
   * @member {String} verifyResult
   */
  exports.prototype['verifyResult'] = undefined;
  /**
   * token 用于对比手机号
   * @member {String} verifyToken
   */
  exports.prototype['verifyToken'] = undefined;
  /**
   * 原手机号，需要与数据库原值一致，若数据库为空，这里可以为空
   * @member {String} phoneNum
   */
  exports.prototype['phoneNum'] = undefined;
  /**
   * accountId
   * @member {Integer} accountId
   */
  exports.prototype['accountId'] = undefined;



  export default exports;
;


