<template>
  <div class="home">
    <div class="category_tab">
      <div :class="{category_item: true, active: gradationIndex == gradationIdx}" v-for="(gradation, gradationIdx) in gradationList" v-bind:key="gradation.id" @click="() => choiceGradation(gradationIdx)">
        {{gradation.name}}
      </div>
    </div>
	<div v-if="loading" style="text-align: center;">
		加载中...
	</div>
	<div v-else style="display: flex;flex-wrap: wrap;">
		<div @click="() => selectMajor(major.id)" v-for="major in majorList" :key="major.id" class="major_item">
			<div class="major_item_ins">
				<div class="major_title" style="font-size: 14px;">
					<div>{{major.name}}</div>
				</div>
				<div style="width: 30%;display: flex;padding-top: 6px;padding-bottom: 6px;align-items: flex-end;justify-content: center;">
					<img class="major_thumb" :src="major.thumbUrl || 'https://fm-cloud-general-prd.oss-cn-shenzhen.aliyuncs.com/dev/c02.jpeg'" />
					<div style="position: absolute;width: 50px;text-align: center;background: #fc85279c;color: #e0e0e0;border-radius: 0 0 5px 5px;">{{major.courseStandardCount}}</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <div style="text-align: center;color: #c1c1c1;margin-bottom: 10px;">version: {{version}}</div> -->
  </div>
</template>

<script>
import clone from 'clone'
import DictApi from '@/api/src/dict/api/DictApi.js'
import CourseStandardApi from '@/api/src/fragment/api/CourseStandardApi.js'
const dictApi = new DictApi()
const courseStandardApi = new CourseStandardApi();
export default {
  name: 'MajorList',
  data() {
    return {
      loading: true,
      active: 1,
      activeNames: ['0'],
      gradationIndex: 0,
      pageNum: 1,
      majorList: [],
      gradationList: [],
	    version: undefined
    }
  },
  async mounted() {
    let resp = await dictApi.getGradation();
    console.log(resp)
    this.gradationList = resp
    this.gradationIndex = 0
    this.pageNum = 1
    await this.fetchData()
  },
  methods: {
    selectMajor(majorId) {
      console.log(majorId)
      this.$router.push({name: 'courseList', params: {majorId}})
      // this.$router.push({name: 'CourseList', query: {majorId}})
    },
    async fetchData() {
      this.loading = true
      let data = await courseStandardApi.getMajorStatistics(this.pageNum, this.gradationList[this.gradationIndex].id);
      this.majorList = data
      this.loading = false
    },
    choiceGradation(gradationIdx) {
      this.gradationIndex = gradationIdx
      this.pageNum = 1
      this.fetchData()
    },
    loadMore() {
      if (this.loading) {
        return;
      }
      if(this.hasNextPage){
        this.pageNum = this.pageNum + 1
        this.fetchData()
      }else{
        // alert('无更多碎片')
      }
    },
    goDetail(id) {
      console.log(id)
      // if(navigator.userAgent.includes(CLIENT_AGENT) && window.dsBridge){
        window.dsBridge.call("callfragmentNode", {courseStandardId: id});
      // }else{
      //   window.open(`https://cloud.aefree.com/api/course_standard/${id}.html`)
      // }
      // try{
      //   window.dsBridge.call("callfragmentNode", params);
      // }catch(e) {

      // }
      // try{
      //     window.open(`https://cloud.aefree.com/api/course_standard/${id}.html`)
      // }catch(e) {

      // }
    },
  }
}
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  .major_item {
	height: 156rpx;
	width: 50%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
  }
  .major_item_ins {
	  margin: 7px 10px 7px 10px;
	  display: flex;
	  align-items: center;
    justify-content: space-between;
    width: 100%;
	border-radius: 4px;
	border-radius: 4px;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  }
  .major_thumb{
	margin: 0 auto;
    border-radius: 5px;
    object-fit: cover;
    width: 50px;
	height: 56px;
	// height: 82%;
  }
  .major_title {
	  width: 70%;
    // position: absolute;
    font-size: 12px;
	text-align: center;
	font-weight: bold;
  }
  .major_card {
    // border-radius: 5px;
    // box-shadow: 0px 2px 6px 0px #cccccc;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-around;
    .major {
      display: flex;
      justify-content: space-between;
      .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .statistics_card_container {
    margin-top: 10px;
    display: flex;
    >:first-child{
      margin-right: 5px;
    }
    >:last-child{
      margin-left: 5px;
    }
    .statistics_card {
      width: 50%;
      border-radius: 5px;
      box-shadow: 0px 2px 6px 0px #cccccc;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 110px;
      .number {
        font-size: 30px;
        font-weight: bold;
      }
      .label {
        font-size: 12px;
      }
    }
  }
  .line {
    height: 1px;
    background: #66666626;
    border-radius: 10px;
  }
  .course_container {
    border-radius: 5px;
    box-shadow: 0px 2px 6px 0px #cccccc;
    padding: 10px;
    .header {
      display: flex;
      justify-content: space-between;
    }
    .container {
      margin-top: 20px;
      .course_item {
        display: flex;
        // border-radius: 5px;
        // box-shadow: 3px 3px 3px 0px #cccccc;
        margin-top: 20px;
        img{
          width: 100%;
          height: 100%;
        }
        .info {
          padding: 10px 20px 10px 20px;
        }
      }
    }
  }
  .category_tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 42px;
    line-height: 42px;
    // padding-bottom: 5px;
    // padding-top: 5px;
    .category_item {
      flex: 1;
	  font-size: 16px;
      // border-radius: 1rem;
      height: 42px;
      line-height: 42px;
      text-align: center;
      &:first-child {
		margin-right: 10px;
        // border-radius: 1rem 0 0 1rem;
      }
      &:last-child {
		margin-left: 10px;
        // border-radius: 0 1rem 1rem 0;
      }
    }
    .active {
		color: #FF8826;
      border-bottom: 2px solid #fe8726;
    }
  }
}
</style>
  