/**
 * 碎片
 * 碎片API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import CourseRankListVo from './CourseRankListVo';
import CourseStandardVo from './CourseStandardVo';
import MajorRankListVo from './MajorRankListVo';
import MajorStatisticsDetailVo from './MajorStatisticsDetailVo';




  /**
   * The FragmentHomePageVo model module.
   * @module fragment/model/FragmentHomePageVo
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>FragmentHomePageVo</code>.
   * @alias module:fragment/model/FragmentHomePageVo
   * @class
   */
  var exports = function() {
    var _this = this;





  };

  /**
   * Constructs a <code>FragmentHomePageVo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:fragment/model/FragmentHomePageVo} obj Optional instance to populate.
   * @return {module:fragment/model/FragmentHomePageVo} The populated <code>FragmentHomePageVo</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'myMajor')) {
        obj['myMajor'] = MajorStatisticsDetailVo.constructFromObject(data['myMajor']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'courseBrowsingHistory')) {
        obj['courseBrowsingHistory'] = ApiClient.convertToType(data['courseBrowsingHistory'], [CourseStandardVo]);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'topCourse')) {
        obj['topCourse'] = ApiClient.convertToType(data['topCourse'], [CourseRankListVo]);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'topMajor')) {
        obj['topMajor'] = ApiClient.convertToType(data['topMajor'], [MajorRankListVo]);
      }
    }
    return obj;
  }

  /**
   * 专业
   * @member {module:fragment/model/MajorStatisticsDetailVo} myMajor
   */
  exports.prototype['myMajor'] = undefined;
  /**
   * @member {Array.<module:fragment/model/CourseStandardVo>} courseBrowsingHistory
   */
  exports.prototype['courseBrowsingHistory'] = undefined;
  /**
   * 精品课程
   * @member {Array.<module:fragment/model/CourseRankListVo>} topCourse
   */
  exports.prototype['topCourse'] = undefined;
  /**
   * 热门专业
   * @member {Array.<module:fragment/model/MajorRankListVo>} topMajor
   */
  exports.prototype['topMajor'] = undefined;



  export default exports;
;


