/**
 * 用户接口
 * 用户接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The ChaoxingCodeForm model module.
   * @module token/model/ChaoxingCodeForm
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>ChaoxingCodeForm</code>.
   * @alias module:token/model/ChaoxingCodeForm
   * @class
   * @param appId {String} appId
   * @param code {String} 超星给予的获取access_token的票据code码
   * @param state {String} 作为获取用户信息的条件参数之一（在下面获取用户信息接口中原封传回即可）
   */
  var exports = function(appId, code, state) {
    var _this = this;

    _this['appId'] = appId;
    _this['code'] = code;
    _this['state'] = state;
  };

  /**
   * Constructs a <code>ChaoxingCodeForm</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:token/model/ChaoxingCodeForm} obj Optional instance to populate.
   * @return {module:token/model/ChaoxingCodeForm} The populated <code>ChaoxingCodeForm</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'appId')) {
        obj['appId'] = ApiClient.convertToType(data['appId'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'state')) {
        obj['state'] = ApiClient.convertToType(data['state'], 'String');
      }
    }
    return obj;
  }

  /**
   * appId
   * @member {String} appId
   */
  exports.prototype['appId'] = undefined;
  /**
   * 超星给予的获取access_token的票据code码
   * @member {String} code
   */
  exports.prototype['code'] = undefined;
  /**
   * 作为获取用户信息的条件参数之一（在下面获取用户信息接口中原封传回即可）
   * @member {String} state
   */
  exports.prototype['state'] = undefined;



  export default exports;
;


