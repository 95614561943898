<template>
  <div class="home">
    <van-search v-model="searchText" @search="onSearch" placeholder="请输入搜索关键词" />
    <van-list
      id="fragmentList" 
      class="fragment_list"
      :immediate-check="false"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad">
      <div class="fragment_item" v-for="fragment in fragmentList" v-bind:key="fragment.id.toString()" @click="() => goDetail(fragment.id)">
        <div class="content">
          <div class="thumb">
            <img src="/images/default_fragment.jpg"/>
          </div>
          <div class="info">
            <div class="title">
              <span class="tag" v-if="fragment.type > 0">
                <img :src="tagMap[fragment.type]"/>
              </span>
              <span v-html="fragment.title"></span>
            </div>
            <div class="summary" v-html="fragment.summary"></div>
            <div class="knowedge">
              <span>{{fragment.knowledge.name}}</span>
            </div>
          </div>
        </div>
        <img v-if="fragment.thumbUrl" src="/images/static/1.jpg"/>
      </div>
    </van-list>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations } from 'vuex'
import FragmentApi from '@/api/src/fragment/api/FragmentApi'
const fragmentApi = new FragmentApi()
export default {
  name: 'FragmentSearch',
  components: {
  },
  data() {
    return {
      searchText: undefined,
		  refresherTriggered: false,
      keyword: '',
      loading: false,
      finished: false,
      error: false,
      pageNum: 0,
      fragmentList: [],
	    majorId: undefined,
	    knowledgeId: undefined,
      tagMap: ['', 'https://fm-cloud-dev.oss-cn-shenzhen.aliyuncs.com/common/video.png', 'https://fm-cloud-dev.oss-cn-shenzhen.aliyuncs.com/common/audio.png']
    }
  },
  computed: mapState({
  }),
  async mounted() {
    this.majorId = this.$route.params.majorId
	  this.knowledgeId = this.$route.query.knowledgeId
    // document.title = '碎片搜索'
	  await this.onLoad(1)
  },
  methods: {
    async onLoad(page) {
      if(page == 1) {
        this.fragmentList = []
      }
      page = page || this.pageNum + 1

      let {majorId, knowledgeId} = this
      let resp = await fragmentApi.searchFragment(page, {
        majorId,
        knowledgeId,
        text: this.searchText
      })
      if(!resp.hasNextPage){
        this.finished = true
      }else if(page >= 20){
        this.finished = true
      }else{
        this.finished = false
        this.pageNum = page
      }
      this.fragmentList = this.fragmentList.concat(resp.list)
      this.loading = false
      console.log(this.loading, this.finished)
    },
    goDetail(id) {
      // console.log(id)
      // if(navigator.userAgent.includes(CLIENT_AGENT) && window.dsBridge){
        // window.dsBridge.call("callFragmentDetail", {id});
      let {knowledgeId} = this
      this.$router.push({name: 'fragmentDetail', query: {fragmentId: id}})
      // uni.navigateTo({
      //   url: `/pages/index/index?fragmentId=${id}&knowledgeId=${knowledgeId}`,
      //   fail: (e) => {
      //     console.log(e)
      //   }
      // });
      // }else{
        // window.open(`https://cloud.aefree.com/api/fragment/${id}.html`)
      // }
    },
    async onSearch(keyword) {
      this.searchText = keyword
      await this.onLoad(1)
    }
  }
}
</script>

<style lang="less" scoped>
.home {
	overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .fragment_list {
    display: flex;
    flex-direction: column;
    // padding-left: 10px;
    padding-right: 10px;
	  padding-top: 10px;
    .not_found {
      text-align: center;
      color: #888888;
      margin-top: 20px;
    }
    .fragment_item {
      display: flex;
      margin-bottom: 10px;
      border-bottom: 1px solid #ececec;
      justify-content: space-between;
      padding-bottom: 10px;
      .content {
        display: flex;
        flex-direction: row;
        padding-right: 4px;
        .thumb {
          img {
            height: 80px;
          }
        }
        .info {
          padding-left: 10px;
        }
        .title {
          text-align: left;
          font-weight: bold;
          .tag {
            padding-right: 6px;
            border-radius: 1rem;
            font-size: 14px;
            font-weight: normal;
            img {
              height: 14px;
            }
          }
        }
        .summary {
          text-align: left;
          font-size: 12px;
        }
        .knowedge {
          text-align: left;
          font-size: 12px;
          color: #777777;
          margin-top: 6px;
          span {
            background: #f1a542;
            border-radius: 3px;
            padding-left: 10px;
            padding-right: 10px;
            color: white;
          }
        }
      }
      img {
        height: 50px;
      }
    }
    .active {
      background: #8B335D;
      color: white;
    }
  }
}
</style>
