<template>
    <div class="main">
        <video-player class="video-player vjs-custom-skin vjs-big-play-centered"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @timeupdate="onPlayerTimeupdate($event)"
                @ready="playerReadied"
                @ended="onPlayerEnded($event)" v-show="currentMediaUrl != null">
        </video-player>

        <div class="title-view" v-if="currentFragementDetail != null">
            <div class="title-label-view" v-if="currentFragementDetail.title != null">
                <div class="title">{{ currentFragementDetail.title }}</div>
                <div class="sub-title">{{ currentFragementDetail.knowledge.name }}</div>
            </div>
            <!-- <div class="star"></div> -->
        </div>

        <div class="fragment-content" v-if="currentFragementDetail!= null && currentFragementDetail.summary != null">
            {{ currentFragementDetail.summary }}
            
        </div>
        <div class="fragment-html-v"  v-if="currentFragementDetail!= null && currentFragementDetail.html != null">
            <p v-html="currentFragementDetail.html"></p>
        </div>
        <div class="other-resource" v-if="currentFragementDetail.type==0 && currentFragementDetail.resList.length>0">
			<div class="resource-title">
				关联文件
			</div>

            <van-grid :border="false" :column-num="3">
                <van-grid-item v-for="item in currentFragementDetail.resList" v-bind:key="item.id">
                  <div class="file-view"   >
                    <img src="../assets/otherfile.png" class="file-icon" @click="openfile(item)"/>
                    <a @click="openfile(item)">打开文件</a>
                    <a @click="copyFileLink(item)">复制文件地址</a>
                  </div>
                </van-grid-item>
                
            </van-grid>
		</div>
    </div>
</template>

<script>
import CXJSBridge3T from '@/chaoxing/CXJSBridge3T.js'

import FragmentApi from '@/api/src/fragment/api/FragmentApi'
const fragmentApi = new FragmentApi()

import { videoPlayer } from 'vue-video-player'
import { Toast } from 'vant';

//----  构建请求队列 ------------
const requestQueue = [];
let isProcessing = false;
//-----------------------------

    export default {
        components: {
            videoPlayer
        },
        data() {
            return {
                playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
                    autoplay: false, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",   // 类型
                        withCredentials: false,
                        src: 'https://fm-cloud-general-prd.oss-cn-shenzhen.aliyuncs.com/fragment/8020202339075678208/碳.mp4' // 测试可用M3U8连接
                    }],
                    poster:  require("../assets/fragment_cover_img.png"), //你的封面地址
                    width: '480px', // 视频框的宽度
                    notSupportedMessage: '',
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true
                    }
                },
                gk: "",
                currentFragementDetail:{
					type:null,
					knowledge:{},
					resList:{},
				},
				currentMediaUrl:null,
				otherFragmentList:{},
				courseStandardId:null,
				knowledgeId:null,
				fragmentId:null,
				isPlaying:false,
				starId:null,
                time: 0,
                timer: null,
                isPlaying:false,
                report_time_queue:[],
                tempTimeLabel:"",
                postStatisticsData:{},
                sessionId:null,
                lastFlagTime:-1,
                currentCostTime:0
            }
        },
        computed: {
            player() {
                return this.$refs.videoPlayer.player
            }
        },
        watch: {
            gk: function () {
            }
        },
        
        mounted() {
            var detailParamer = this.$route.query
            console.log(detailParamer)
            this.knowledgeId = detailParamer.knowledgeId
            this.fragmentId = detailParamer.fragmentId
            this.courseStandardId = detailParamer.courseStandardId
            console.log(this.knowledgeId)
            console.log(this.fragmentId)
            this.loadFragmentDetail(this.fragmentId)
            this.starId = detailParamer.starId

            let that = this
            document.addEventListener("keydown", function (e) {
                if (e.keyCode === 39) {
                    // that.goFast()
                }
                if (e.keyCode === 37) {
                    // that.goBack()
            }
            })
        },
        onUnmounted(){
            console.log('=====onUnmounted======');
            console.log()
            var emptyFlag = (Object.keys(this.postStatisticsData).length === 0 && this.postStatisticsData.constructor === Object)
            if(emptyFlag != true){
                this.postStatisticsData.endTime = new Date().getTime();
                this.statisticsUpload()
            }
        },
        beforeDestroy(){
            // clearInterval(this.timer);
        },
        methods: {
            
            loadFragmentDetail(f_id){
				
				var temp_id = f_id
				if (temp_id == null) {
					temp_id = this.fragmentId
				}
				var courseStandardId = this.courseStandardId
				console.log(temp_id)
				fragmentApi.getFragment(temp_id,{courseStandardId}).then(resp => {
					console.log("resp=====》")
					console.log(resp)
					this.setMainData(resp)
				})
			},
			
			setMainData(dataParse){
			
				console.log(dataParse);
							
				this.currentFragementDetail = dataParse
                if(this.currentFragementDetail.type == 1){
                    if (this.currentFragementDetail.resList[0] != undefined){
					
					var resArr = this.currentFragementDetail.resList[0]


                    this.knowledgeId = dataParse.knowledge.id
                    this.courseStandardId = dataParse.courseStandard.id
                    console.log(resArr);
                    this.player.pause()
                    this.currentMediaUrl = resArr.mediaUrl
                    this.currentFid = dataParse.id.toString()
                    this.playerOptions.src = this.currentMediaUrl
                    this.player.src(this.currentMediaUrl)
                    this.player.play()

                    this.sessionId = null
                    this.postStatisticsData.sessionId = null
                    this.lastFlagTime = -1
                    //----------------- 先传一个空的 ---------------
                    this.resetPostStatisticsData()
                    this.postStatisticsData.dottingTimeList = []
                    this.addStatisticsNullQueue()
                    //--------------------------------------------
                    }
                }else{
                    // if (this.currentFragementDetail.resList[0] != undefined){
					
					// var resArr = this.currentFragementDetail.resList[0]


                    // this.knowledgeId = dataParse.knowledge.id
                    // this.courseStandardId = dataParse.courseStandard.id
                    // console.log(resArr);
                    // this.player.pause()
                    // this.currentMediaUrl = resArr.mediaUrl
                    // this.currentFid = dataParse.id.toString()
                    // this.playerOptions.src = this.currentMediaUrl
                    // this.player.src(this.currentMediaUrl)
                    // this.player.play()

                    // this.sessionId = null
                    // this.postStatisticsData.sessionId = null
                    // this.lastFlagTime = -1
                    // //----------------- 先传一个空的 ---------------
                    // this.resetPostStatisticsData()
                    // this.postStatisticsData.dottingTimeList = []
                    // this.addStatisticsNullQueue()
                    // //--------------------------------------------
                    // }
                }
			},
            gotoDetail(){
            },
            openfile(item){
                console.log(item)
                jsBridge.postNotification('CLIENT_OPEN_URL', {"checkLogin":0,"tabBarShowFlag":0,"toolbarType":0,"title":"","loadType":2,"webUrl":item.mediaUrl}) ; 

                // jsBridge.postNotification('CLIENT_OPEN_EXTERNAL', item.mediaUrl);
            },
            copyFileLink(item){
                // var input = document.createElement("input"); // 创建input对象
                // input.value = item.mediaUrl; // 设置复制内容
                // document.body.appendChild(input); // 添加临时实例
                // input.select(); // 选择实例内容
                // document.execCommand("Copy"); // 执行复制
                // document.body.removeChild(input); // 删除临时实例
                // Toast('文件地址已复制');
                this.$copyText(item.mediaUrl).then(function (e) {
                    Toast('文件地址已复制');
                    console.log(e)
                }, function (e) {
                    console.log(e)
                })
                
            },
            onPlayerPlay(player) {
                // 播放回调
                console.log("当前视频总时长------")
                console.log(player.cache_.duration)
                this.isPlaying = true
            },
            onPlayerPause(player) {
                // 暂停回调
                console.log("当前视频暂停------")
                this.isPlaying = false
                var emptyFlag = (Object.keys(this.postStatisticsData).length === 0 && this.postStatisticsData.constructor === Object)
                if(emptyFlag != true){
                    this.lastFlagTime = -1
                    this.addStatisticsQueue()
                    this.resetPostStatisticsData()
                    this.report_time_queue = []
                }
                
            },
            onPlayerEnded($event) {
                //播放完成回调
                console.log("当前播放完成------")
                this.isPlaying = false
                this.addStatisticsQueue()
                this.resetPostStatisticsData()
                this.report_time_queue = []
            },
            goFast() {
                //快进
                // this.gk += 5;
                // this.$refs.videoPlayer.player.currentTime(this.gk)
            },
            goBack() {
                //后退
                // this.gk -= 5;
                // this.$refs.videoPlayer.player.currentTime(this.gk);
            },
            onPlayerTimeupdate(player) {
                // console.log('----------player.cache_.currentTime------------')
                // console.log(player.cache_.currentTime)
                var timeFlag = Math.trunc(this.player.cache_.currentTime);
                if (timeFlag % 5 === 0 && this.lastFlagTime != timeFlag) {//每五秒要上传
                    if (!this.report_time_queue.includes(timeFlag)) {
                        
                        this.lastFlagTime = timeFlag
                        this.report_time_queue.push(timeFlag);
                        
                    }
                    console.log(this.report_time_queue)
                    this.postStatisticsData.dottingTimeList = this.report_time_queue
                    console.log('开始上报-------')
                    this.addStatisticsQueue()
                    this.resetPostStatisticsData()
                    this.report_time_queue = []
                }else{
                    
                    if (!this.report_time_queue.includes(timeFlag) && this.lastFlagTime != timeFlag) {
                        this.report_time_queue.push(timeFlag);
                    }
                    this.postStatisticsData.dottingTimeList = this.report_time_queue
                }

            },
            playerReadied(player) {
                
                // player.currentTime(this.gk);
            },
            /*
            startTimer(){
                this.timer = setInterval(() => {
                    if(this.isPlaying==false){
                        // clearInterval(this.timer);
                        console.log('没有播放，忽略--------')
                    }else{
                        this.time++;
                        console.log('正在播放--------')
                        if (this.time % 5 === 0) {
                            //信息上报
                            var timeFlag = Math.trunc(this.player.cache_.currentTime);
                            this.report_time_queue.push(timeFlag)
                            console.log(this.report_time_queue)
                            this.postStatisticsData.endTime = new Date().getTime();
                            this.postStatisticsData.dottingTimeList = this.report_time_queue
                            console.log('开始上报-------')
                            this.statisticsUpload()
                            this.resetPostStatisticsData()
                            this.report_time_queue = []
                        }else{
                            // console.log('----this.player.cache_.currentTime-------')
                            //console.log(this.player.cache_.currentTime)
                            var timeFlag = Math.trunc(this.player.cache_.currentTime);
                            this.report_time_queue.push(timeFlag)
                            this.postStatisticsData.dottingTimeList = this.report_time_queue
                        }
                    }
                    
                }, 1000);
            },
            */

            //---------------- 请求队列相关 -------------

        addStatisticsQueue(){
            
            var that = this
           
            that.postStatisticsData.sessionId = that.sessionId
            that.addRequest(() => that.statisticsUpload.call())
                        .then(response => console.log('Response:', response))
                        .catch(error => console.error('Error:', error));
        },

        addStatisticsNullQueue(){

            var that = this
            that.resetPostStatisticsData()
            that.postStatisticsData.dottingTimeList = [] 
            delete that.postStatisticsData.sessionId
            that.addRequest(() => that.statisticsUpload.call())
                    .then(response => console.log('Response:', response))
                    .catch(error => console.error('Error:', error));
        },

        addRequest(requestFunction) {
            var that = this
            return new Promise((resolve, reject) => {
                requestQueue.push({ requestFunction, resolve, reject });
                that.processQueue();
            });
        },
        

        async processQueue() {
            if (isProcessing) return;
            isProcessing = true;

            while (requestQueue.length > 0) {
                const { requestFunction, resolve, reject } = requestQueue.shift();
                try {
                const result = await requestFunction();
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            }

            isProcessing = false;
        },


        resetPostStatisticsData(){
            this.postStatisticsData = {}
            this.postStatisticsData.fragmentId = this.currentFid
            this.postStatisticsData.knowledgeId = this.knowledgeId
            this.postStatisticsData.courseStandardId = this.courseStandardId
        },

        statisticsUpload(){

            if(!this.postStatisticsData.dottingTimeList){
                return
            }

           
            this.postStatisticsData.sessionId = this.sessionId

            if(this.postStatisticsData.dottingTimeList.length == 1 && this.postStatisticsData.dottingTimeList[0]==0){
                
                this.postStatisticsData.dottingTimeList = []
                this.postStatisticsData.sessionId = null
            }
            
            var that = this
            // 定义要发送的数据

            const postStatisticsData = this.postStatisticsData;
            console.log('apiClient postStatisticsData', postStatisticsData)
            let token = localStorage.getItem('Authorization')
            console.log('apiClient token', token)
            var headers = {}
            if(token && token != 'undefined') {
                headers = {
                    'Authentication': token // 将 your_token_here 替换为您的实际认证令牌
                };
            }else{
                const Authorization  = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4MDEyMDE4Njk5MzY5MzczNjk2IiwiYXVkIjpbIjEiLCI4Il0sIm5iZiI6MTcxMjM4NzEwOSwiaXNzIjoiRURVLTEiLCJleHAiOjE3NDM0OTExMDksImlhdCI6MTcxMjM4NzEwOSwianRpIjoiYjllN2Q1OGQtNGY4Zi00M2VkLTk4ODQtNmU5YzIxOTNlN2VhIn0.5qzGu7qFBIuRvvHGJA0TF10r1MsTKZDvChTDZhVhGmY';
                headers = {
                    'Authentication': Authorization // 将 your_token_here 替换为您的实际认证令牌
                };
            }
            // 定义要添加的 Authentication 头字段
            

            // 发送 POST 请求，将 headers 参数传递给 fetch 的第二个参数
            fetch('https://fragmentory-dev-fragmentory-dev-imxpolwnhm.cn-shenzhen.fcapp.run', {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json' // 设置 Content-Type 为 application/json
            },
            body: JSON.stringify(postStatisticsData)
            })
            .then(response => {
                if (!response.ok) {
                   
                    const error = new Error('Network response was not ok');
                    error.status = response.status;
                    error.statusText = response.statusText;
                    throw error;
                }
                return response.json();
            })
            .then(data => {
                // Toast('成功发送请求:'+data);
                that.sessionId = data.sessionId
                that.currentCostTime = data.costTime
                console.log('成功发送请求:', that.sessionId);
            })
            .catch(error => {

                if(error.status == 400){
                   
                    that.sessionId = null
                    that.player.pause()
                }
            });
        }
        },
    }
</script>

<style lang="less" scoped>
    .main{
        display: flex;
        flex-direction: column;
    }

    .top-view{
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        height: 214px;
        background-image: url('../assets/fragment_cover_img.png');
        background-size: cover; /* 控制背景图片大小 */
        background-position: center; /* 控制背景图片位置 */

        max-height: 214px;;
        margin: 0 auto; /* 使内容水平居中 */
        max-width: 480px;
    }

    .video-player{
        position: fixed;
        display: contents;
        top: 0;
        left: 0;
        right: 0;
        height: 214px;
        max-height: 214px;;
        margin: 0 auto; /* 使内容水平居中 */
        max-width: 480px;
        :deep(.vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode)),:deep(.vjs-fluid:not(.vjs-audio-only-mode)) {
            padding-top: 214px;
        }        
    }

    .title-view{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        margin-left: 15px;
        margin-right: 15px;
        .title-label-view{
            display: flex;
            flex-direction: column;
            .title{
                font-weight: 500;
                font-size: 20px;
                color: #000000;
            }

            .sub-title{
                font-weight: 400;
                font-size: 10px;
                color: #FF8826;
                margin-top: 10px;
            }

        }
        .star{
            height: 30px;
            width: 30px;
            margin-left: auto;
            background-color: #FF8826;
        }
    }

    .fragment-content{
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 11px;
        color: #000000;
        line-height: 20px;
        margin: 15px;
    }

    .fragment-html-v{
        margin: 15px;
        margin-top: 5px;
    }

    .other-fragment{
        display: flex;
        flex-direction: column;
        margin: 15px;
        .title-label{
            font-weight: bold;
            font-size: 17px;
            color: #000000;
            margin-bottom: 20px;
        }
        .other-fragment-list{
            display: flex;
            flex-direction: column;
            .other-fragment-item{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 10px;
                .other-fragment-item-img{
                    width: 62px;
                    height: 50px;
                    background: rgba(37,34,69,0.29);
                    border-radius: 4px;
                }
                .other-fragment-name{
                    font-family: PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: #000000;
                    margin: 10px;
                }
            }
        }
    }
   
    .other-resource {
		background-color: white;
	}
	
	.file-view {
		width: 150rpx;
		height: auto;
		margin: 20rpx;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        text-align: center;
        color: rgb(60, 100, 222);
        a{
            margin-top: 5px;
            text-decoration: underline;
            text-decoration-color: rgb(60, 100, 222); /* 下划线颜色 */
            text-decoration-style: dotted; /* 下划线样式，可选值有：solid, double, dotted, dashed, wavy */
            text-underline-offset: 2px; /* 下划线偏移量，控制下划线与文本的距离 */
        }
	}
	
	.file-icon {
		width: 40px;
		height: 40px;
	}
	
	.resource-title {
		background-color: white;
		font-size: 17px;
		font-weight: bold;
		padding-top: 10rpx;
		padding-left: 10rpx;
		padding-left: 10rpx;
		padding-bottom: 20rpx;
        margin-left: 15px;
	}
</style>