<template>
  <div class="home">
    <div class="course_info">
      <div style="width: 25%;display: flex;align-items: center;">
        <img :src="courseStandard.thumbUrl || `https://fm-cloud-general-prd.oss-cn-shenzhen.aliyuncs.com/img/course_standard/default/default_${courseStandard.id.toString().substr(-1)}.png`"/>
      </div>
      <div style="width: 75%;" class="info">
        <div style="font-weight: bold;font-size:18px">
			<div style="font-weight: bold;font-size:18px;">
			  <span v-if="courseStandard.awarded" style="font-size: 12px;color: #FFFFFF;margin-right: 5px;padding-left: 2px;padding-right: 2px;height: 17px;background: #FF8826;font-weight: 200;">精</span>
			  <span style="vertical-align: middle;">{{courseStandardName}}</span>
			</div>
		</div>
        <!-- <view style="height: 1px;background: #d4d4d4;margin-top: 10px;margin-bottom: 10px;"></view> -->
        <div class="summary">{{courseStandardSummary}}</div>
      </div>
    </div>
	<!-- <div class="top-line">
		<img src="../assets/f_list_top_line.png" alt="" class="row-image">
	</div> -->
	<!-- <div class="top-buttons-view">
		<div class="top-buttons-view-fstar" @click="fStarAction">
			<div>
				碎片收藏
			</div>
			<div class="top-buttons-view-check">
				<div class="top-buttons-view-sub-title">
					去看看
				</div>
				<img class="top-buttons-view-small-icon" src="../assets/course_detail_top_icon_small.png"/>
			</div>
		</div>
		<div class="top-buttons-view-pstar" @click="pStarAction">
			<div>
				习题收藏
			</div>
			<div class="top-buttons-view-check">
				<div class="top-buttons-view-sub-title">
					去看看
				</div>
				<img class="top-buttons-view-small-icon" src="../assets/course_detail_top_icon_small.png"/>
			</div>
		</div>
	</div> -->
	<van-collapse open="true" v-model="activeNames" style="border-radius: 5px;margin-top: 20px;">
	    <van-collapse-item  v-for="(knowledge, index) in knowledgeTree" :key="index" :title="`${knowledge.name}`" :name="index">
	        <div v-for="(subKnowledge, subIndex) in knowledge.children" :key="subKnowledge.id" class="sub_knowledge" @click="() => goKnowledgeDetail(subKnowledge.id,knowledge.name,knowledge.children,index,subIndex)">
				{{subKnowledge.name}}
			</div>
	    </van-collapse-item>
	</van-collapse>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations } from 'vuex'
import { toTree } from "../util/tree";
import CourseStandardApi from '../api/src/fragment/api/CourseStandardApi'
const courseStandardApi = new CourseStandardApi()
export default {
  name: 'KnowledgeMap',
  components: {
  },
  data() {
    return {
      activeNames: [0],
      knowledgeTree: [],
      courseStandardName: '',
      courseStandardSummary: '',
	  courseStandardId:undefined,
	  courseStandard: {
		  id: 0
	  }
    }
  },
  computed: mapState({
  }),
  mounted() {
    this.fetchData()
  },
  onLoad(option) {
  	this.courseStandardId = option.courseStandardId
  },
  methods: {
    fetchData() {
      let {courseStandardId} = this.$route.query
	//   let courseStandardId = this.courseStandardId
      courseStandardApi.getDetail(courseStandardId).then(resp => {
        let tree = toTree(resp.courseTarget.knowledgeMap)
        this.knowledgeTree = tree
        this.courseStandardName = resp.name
        this.courseStandardSummary = resp.summary
		this.courseStandard = resp
		console.log(resp)
		console.log("------------knowledgeTree--------------")
		console.log(this.knowledgeTree)
      })
    },
    goKnowledgeDetail(knowledgeId,knowledgeName,knowledges,index,subIndex) {
      let {courseStandardId} = this.$route.query
	//   let courseStandardId = this.courseStandardId
	  console.log(knowledges)
	  console.log(knowledgeName)
	  let knowledgeParams = {}
	  var fid = "-1"
	  knowledgeParams = {knowledgeId,courseStandardId,knowledgeName,index,subIndex,fid}
	  
      this.$router.push({name: 'fragmentView', params: knowledgeParams})
    },
	fStarAction(){
		let courseStandardId = this.courseStandardId
		
		uni.setStorage({
				  key:'courseStandardId',
				  data:{courseStandardId},
				  success:()=>{
					  console.log('存入成功')
				  }
		})
		uni.navigateTo({
			url: `/pages/fragmentStarListNvue/fragmentStarListNvue`,
			fail: (e) => {
				console.log(e)
			}
		});
	},
	pStarAction(){
		let courseStandardId = this.courseStandardId
		
		uni.setStorage({
				  key:'courseStandardId',
				  data:{courseStandardId},
				  success:()=>{
					  console.log('存入成功')
				  }
		})
		uni.navigateTo({
			url: `/pages/fragmentStarListNvue/fragmentStarListNvue`,
			fail: (e) => {
				console.log(e)
			}
		});
	}
  }
}
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  .course_info {
    display: flex;
    padding: 20px 15px 20px 15px;
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.05);
    img{
      width: 100%;
    }
    .info {
      padding: 0px 0 0px 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
      .summary {
        font-size:12px;
		color:#666666;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp:3;
        // min-height: 51px;
      }
    }
  }
  .card {
    border-radius: 5px;
    box-shadow: 0px 2px 6px 0px #cccccc;
    padding: 10px;
  }
  .sub_knowledge {
    padding: 10px 10px 10px 10px;
	color: #828282;
	font-size: 14px;
	padding-left: 15px;
  }
  .line {
    height: 1px;
    background: #66666626;
    border-radius: 10px;
  }
}
.uni-collapse-cell--open {
	background: #fff;
	border-radius: 5px;
}
.top-line {
	width: 100%;
	height: 28px;
	margin-bottom: 10px;
	align-items: center;
}

.row-image {
	width: 100%;
	height: 10px;
	align-items: center;
}

.top-buttons-view {
	width: 100%;
	height: 70px;
	display: flex;
	flex-direction: row;
}

.top-buttons-view-fstar{
	width: 50%;
	height: 70px;
	display: flex;
	flex-direction: column;
	background: url(../assets/course_top_buttons_bg.png) no-repeat;
	background-size:100%;
	display: inline-block;
	align-content: center;
	justify-content: center;
	margin-left: 20px;
	margin-right: 10px;
	align-items: center;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 500;
	line-height: 22px;
	color: #333333;
	padding: 20px;
	padding-top: 0px;
}

.top-buttons-view-pstar{
	width: 50%;
	height: 70px;
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	margin-right: 20px;
	background: url(../assets/course_top_buttons_bg.png) no-repeat;
	background-size:100%;
	display: inline-block;
	align-content: center;
	justify-content: center;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 500;
	line-height: 22px;
	color: #333333;
	padding: 20px;
	padding-top: 0px;
}

.top-buttons-view-check {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 10px;
}

.top-buttons-view-small-icon {
	width: 15px;
	height: 15px;
	margin-left: 10px;
}

.top-buttons-view-sub-title{
	
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 400;
	line-height: 17px;
	color: #666666;
}
</style>
