<template>
  <div class="main">
    <div> 
      <div> 
        {{ userInfo.msg }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Mine',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.security.userInfo,
    }),
  },
  mounted() {
  },
  methods: {
  }
}
</script>
  