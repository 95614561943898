/**
 * 碎片
 * 碎片API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The FragmentSummaryVo model module.
   * @module fragment/model/FragmentSummaryVo
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>FragmentSummaryVo</code>.
   * @alias module:fragment/model/FragmentSummaryVo
   * @class
   */
  var exports = function() {
    var _this = this;








  };

  /**
   * Constructs a <code>FragmentSummaryVo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:fragment/model/FragmentSummaryVo} obj Optional instance to populate.
   * @return {module:fragment/model/FragmentSummaryVo} The populated <code>FragmentSummaryVo</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'required')) {
        obj['required'] = ApiClient.convertToType(data['required'], 'Boolean');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'sortKey')) {
        obj['sortKey'] = ApiClient.convertToType(data['sortKey'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'knowledgeId')) {
        obj['knowledgeId'] = ApiClient.convertToType(data['knowledgeId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'summary')) {
        obj['summary'] = ApiClient.convertToType(data['summary'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'Integer');
      }
    }
    return obj;
  }

  /**
   * id
   * @member {Integer} id
   */
  exports.prototype['id'] = undefined;
  /**
   * 必学
   * @member {Boolean} required
   */
  exports.prototype['required'] = undefined;
  /**
   * 序号
   * @member {Integer} sortKey
   */
  exports.prototype['sortKey'] = undefined;
  /**
   * 知识点id
   * @member {Integer} knowledgeId
   */
  exports.prototype['knowledgeId'] = undefined;
  /**
   * 标题
   * @member {String} title
   */
  exports.prototype['title'] = undefined;
  /**
   * 摘要
   * @member {String} summary
   */
  exports.prototype['summary'] = undefined;
  /**
   * 媒体资源类型 0,文字，1:视频；2音频，3:视频音频
   * @member {Integer} type
   */
  exports.prototype['type'] = undefined;



  export default exports;
;


