const toTree = function(data) {
    let result = []
    if (!Array.isArray(data)) {
      return result
    }
    data.forEach(item => {
		item.label = item.name
        delete item.children;
    });
    let map = {};
    data.forEach(item => {
        map[item.id] = item;
    });
    data.forEach(item => {
      let parent = map[item.pid];
      if (parent) {
          (parent.children || (parent.children = [])).push(item);
      } else {
          result.push(item);
      }
    });
    return result;
  }

const searchTree = (ele, key, value, child) => {
  if (ele[key] == value) {
      return ele;
  } else if (ele[child] != null) {
      let i;
      let result = null;
      for (i = 0; result == null && i < ele[child].length; i++) {
          result = searchTree(ele[child][i], key, value, child);
      }
      return result;
  }
}

const treeToArray = (ele, child, array) => {
  array.push(ele)
  if (ele[child] && ele[child].length > 0) {
    for (let i = 0; i < ele[child].length; i++) {
      treeToArray(ele[child][i], child, array);
    }
  }
}

exports.toTree = toTree;
exports.searchTree = searchTree;
exports.treeToArray = treeToArray;