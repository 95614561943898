/**
 * 碎片
 * 碎片API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The MajorVo model module.
   * @module fragment/model/MajorVo
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>MajorVo</code>.
   * @alias module:fragment/model/MajorVo
   * @class
   */
  var exports = function() {
    var _this = this;







  };

  /**
   * Constructs a <code>MajorVo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:fragment/model/MajorVo} obj Optional instance to populate.
   * @return {module:fragment/model/MajorVo} The populated <code>MajorVo</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'summary')) {
        obj['summary'] = ApiClient.convertToType(data['summary'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'thumbUrl')) {
        obj['thumbUrl'] = ApiClient.convertToType(data['thumbUrl'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'gradationId')) {
        obj['gradationId'] = ApiClient.convertToType(data['gradationId'], 'Integer');
      }
    }
    return obj;
  }

  /**
   * 专业 id
   * @member {Integer} id
   */
  exports.prototype['id'] = undefined;
  /**
   * 专业名称
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * 专业代码
   * @member {String} code
   */
  exports.prototype['code'] = undefined;
  /**
   * 描述
   * @member {String} summary
   */
  exports.prototype['summary'] = undefined;
  /**
   * 图片url
   * @member {String} thumbUrl
   */
  exports.prototype['thumbUrl'] = undefined;
  /**
   * 专业层次id 1 中职，2高职
   * @member {Integer} gradationId
   */
  exports.prototype['gradationId'] = undefined;



  export default exports;
;


