/**
 * 碎片
 * 碎片API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The FragmentStarForm model module.
   * @module fragment/model/FragmentStarForm
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>FragmentStarForm</code>.
   * @alias module:fragment/model/FragmentStarForm
   * @class
   * @param courseStandardId {Integer} 课程标准id
   * @param star {Boolean} true收藏 false取消收藏
   */
  var exports = function(courseStandardId, star) {
    var _this = this;


    _this['courseStandardId'] = courseStandardId;
    _this['star'] = star;
  };

  /**
   * Constructs a <code>FragmentStarForm</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:fragment/model/FragmentStarForm} obj Optional instance to populate.
   * @return {module:fragment/model/FragmentStarForm} The populated <code>FragmentStarForm</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'fragmentId')) {
        obj['fragmentId'] = ApiClient.convertToType(data['fragmentId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'courseStandardId')) {
        obj['courseStandardId'] = ApiClient.convertToType(data['courseStandardId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'star')) {
        obj['star'] = ApiClient.convertToType(data['star'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * 碎片id
   * @member {Integer} fragmentId
   */
  exports.prototype['fragmentId'] = undefined;
  /**
   * 课程标准id
   * @member {Integer} courseStandardId
   */
  exports.prototype['courseStandardId'] = undefined;
  /**
   * true收藏 false取消收藏
   * @member {Boolean} star
   */
  exports.prototype['star'] = undefined;



  export default exports;
;


