<template>
  <div id="app">
    <van-skeleton :row="20" style="margin-top: 32px" :loading="!inited">
      <router-view/>
    </van-skeleton>
    <van-action-sheet title="选择院校" :close-on-click-overlay="false" :closeable="false" v-model="companyShow" :actions="companyList" @select="selectCompany" />
    <van-action-sheet title="选择校区" :close-on-click-overlay="false" :closeable="false" v-model="chaoxingCompanyShow" :actions="chaoxingCompanyList" @select="selectChaoxingCompany" />
  </div>
</template>
<script>
import CXJSBridge3T from '@/chaoxing/CXJSBridge3T.js'
import { Toast, Dialog } from 'vant';
import constants from '@/util/constants.js'
import TokenApi from '@/account_api/src/token/api/TokenApi.js'
import AccountApi from '@/api/src/account/api/AccountApi.js'
import { del } from 'vue';
const tokenApi = new TokenApi()
const accountApi = new AccountApi()
export default {
  name: 'App',
  data() {
    return {
      chaoxingCompanyShow: false,
      chaoxingCompanyList: constants.chaoxingCompanyList,
      companyShow: false,
      companyList: [],
      accountToken: undefined,
      inited: false
    }
  },
  watch: {
    '$route.name': function() {
      if(this.$route.meta && this.$route.meta.title) {
        document.title = this.$route.meta.title;
        if(window.jsBridge) {
          jsBridge.postNotification('CLIENT_TOOLBAR_TITLE', {'webTitle': this.$route.meta.title});
        }
      }
    }
  },
  async beforeCreate() {
    
  },
  async mounted() {
    await this.initJsBridge()

    console.log(constants)
    if(process.env.NODE_ENV == 'development') {
      this.inited = true
      return
    }
    let token = localStorage.getItem('Authorization')
    console.log('token', token)
    if(token && token != 'undefined') {
      Toast.clear()
      Toast.loading({
        duration: 0,
        message: '登录中',
        forbidClick: true,
      });
      try{
        let resp = await accountApi.current()
        console.log(resp)
        this.$store.commit('security/changeUserInfo', resp)
        this.companyShow = false
        Toast.clear()
        this.inited = true
      }catch(e) {
        await this.chaoxingLogin()
      }
      return
    }else{
      await this.chaoxingLogin()
    }
  },
  methods: {
    async initJsBridge() {
      window.baseForwardUrl = () => {
        let redirectUrl = `${window.location.origin}${window.location.pathname}?t=1`
        if(this.$route.query) {
          let query = Object.assign({}, this.$route.query)
          delete query.appId
          delete query.appKey
          delete query.code
          delete query.state
          delete query.fidEnc
          delete query.uid
          delete query.mappId
          delete query.mappIdEnc
          for(let key in query) {
            redirectUrl = redirectUrl + `&${key}=${query[key]}`
          }
        }

        let resTitle = '云碎片'
        if(this.$route.meta && this.$route.meta.title) {
          resTitle = this.$route.meta.title
        }else if(document.title) {
          resTitle = document.title
        }


        var coverUrl = "https://fm-cloud-general-prd.oss-cn-shenzhen.aliyuncs.com/common/fm_logo.jpg";     //分享出去的logo
        var key = md5(redirectUrl);  //md5.js里面的方法
        jsBridge.postNotification('CLIENT_TRANSFER_INFO', {
          "cataid" : "100000015",
          // "cataName" : "网页",
          // "key" : key,
          "content" : {
            "toolbarType" : 2,
            "resTitle" : resTitle,
            "resUrl" : encodeURI(redirectUrl),
            "resUid" : key,
            "resLogo" : coverUrl,
            "_source" : "",
            "sourceConfig" : "",
            "showContent": 0,  //是否显示描述 =1显示
            "resContent": "分享出去的卡片里面的描述" //描述
          }
        });
        jsBridge.bind('CLIENT_APP_CONFIG', function(object){
          console.log('---CLIENT_APP_CONFIG----')
          console.log(object)
          alert(object)
        });
        jsBridge.postNotification('CLIENT_APP_CONFIG', {}) ; 

        jsBridge.bind('CLIENT_DEVICE_ID', function(object){
          console.log('---CLIENT_DEVICE_ID----')
          console.log(object)
          alert(object)
        });
        jsBridge.postNotification('CLIENT_DEVICE_ID', {}) ; 
      }

      //fragmentView 分享
      window.fragmentViewForwardUrl = () => {
      }

      //分享全局方法
      window.forwardUrl = () => {
        window.baseForwardUrl()
      }

      window._jsBridgeReady = function() {//这个方法相当于jquery的ready方法，客户端会在加载完协议后调用这个方法
        //jsBridge
        try{
          jsBridge.postNotification('CLIENT_CUSTOM_MENU',{
            show:1,
            width:30,
            height:18,
            icon:'http://home.yd.chaoxing.com/res/images/tools/forward.png',
            menu:'',
            children:[{
              menu:'分享',option:'forwardUrl()'
            }]
          });
        }catch(e){
        }
      }



      //获取jsBridge at参数
      try{
        let signal = undefined
        let at = localStorage.getItem('at')
        let atExpired = localStorage.getItem('at_expired')
        console.log(at)
        console.log(atExpired)
        if(at && atExpired && at != 'null' && atExpired != 'null' && new Date().getTime() + 60000 < atExpired) {
          signal = at
        }else{
          let resp = await tokenApi.getChaoxingJSBridgeSignal(1)
          signal = resp.signal
        }
        console.log('at: ' + signal)
        let cXJSBridge3T = new CXJSBridge3T(signal)
        await cXJSBridge3T.init()
        if(window.navigator.userAgent.includes('Android')) {
          window.jsBridge.setDevice('android')
        }else if(window.navigator.userAgent.includes('iPhone')) {
          window.jsBridge.setDevice('ios')
        }
      }catch(e) {
        Toast.fail('jBridge初始化异常');
        return
      }
    },
    async chaoxingLogin() {
      Toast.loading({
        duration: 0,
        message: '登录中',
        forbidClick: true,
      });
      let query = this.$route.query
      if(query.code && query.state && query.appId) {
        try{
          let resp = await tokenApi.getTokenByChaoxingCode({
            appId: query.appId,
            code: query.code,
            state: query.state
          })
          this.accountToken = resp.token
          if(!resp.companyOrgList || resp.companyOrgList.length == 0) {
            throw new Error()
          }
          console.log(resp.companyOrgList)
          this.companyList = resp.companyOrgList
          Toast.clear()
          if(resp.companyOrgList.length == 1) {
            await this.selectCompany(resp.companyOrgList[0])
          }else{
            this.companyShow = true
          }
        }catch(e) {
          Toast.clear()
          Toast.fail(e.message);
          this.chaoxingCompanyShow = true
        // https://auth.chaoxing.com/connect/oauth2/authorize?appid=66c1e8c3d16344b68910de303445045a&redirect_uri=https%3A%2F%2Fv.fmyundianji.com%3FappId%3D66c1e8c3d16344b68910de303445045a%26appKey%3D3N4Gu7Ks83WID2TO&response_type=code&scope=snsapi_base&state=177372
          // window.location.href = `https://auth.chaoxing.com/connect/oauth2/authorize?appid=${query.appId}&redirect_uri=${encodeURIComponent(`https://v.fmyundianji.com?appid=${query.appId}`)}&response_type=code&scope=snsapi_base&state=0`
        }
      }else{
        Toast.clear()
        this.chaoxingCompanyShow = true
      }
    },
    async selectCompany(action) {
      Toast.clear()
      Toast.loading({
        duration: 0,
        message: '登录中',
        forbidClick: true,
      });
      let resp = await accountApi.loginCompany({
        token: this.accountToken,
        companyId: action.id
      })
      this.$store.commit('security/changeUserInfo', resp)
      this.companyShow = false
      Toast.clear()
      this.inited = true
    },
    async selectChaoxingCompany(action) {
      console.log(action)
      Toast.clear()
      Toast.loading({
        duration: 0,
        message: '跳转中',
        forbidClick: true,
      });
      console.log(this.$route)
      // https://auth.chaoxing.com/connect/oauth2/authorize?appid=66c1e8c3d16344b68910de303445045a&redirect_uri=https%3A%2F%2Fv.fmyundianji.com%3FappId%3D66c1e8c3d16344b68910de303445045a%26appKey%3D3N4Gu7Ks83WID2TO&response_type=code&scope=snsapi_base&state=177372
      // let redirectUrl = `${window.location.origin}${window.location.pathname}?appId=${action.appId}&appKey=${action.appKey}`
      let redirectUrl = `${window.location.origin}${window.location.pathname}?appId=${action.appId}`
      if(this.$route.query) {
        let query = Object.assign({}, this.$route.query)
        delete query.appId
        delete query.appKey
        delete query.code
        delete query.state
        for(let key in query) {
          redirectUrl = redirectUrl + `&${key}=${query[key]}`
        }
      }
      // console.log(`https://auth.chaoxing.com/connect/oauth2/authorize?appid=${action.appId}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_base&state=${action.state}`)
      // return
      window.location.href = `https://auth.chaoxing.com/connect/oauth2/authorize?appid=${action.appId}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_base&state=${action.state}`
    }
  }
}
</script>
<style lang="less">
  html,body{
    max-width: 480px;
    margin: 0 auto; /* 使内容水平居中 */
  }
  
</style>
