<template>
  <div class="home">
    <div class="course_container">
      <div class="container">
        <van-list
          :immediate-check="false"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad">
          <div class="course_item" v-for="courseStandard in courseList" :key="courseStandard.id.toString()" @click="() => goKnowledgeDetail(courseStandard.id)">
            <div style="width: 25%;display: flex;align-items: center;">
              <img :src="courseStandard.thumbUrl || `https://fm-cloud-general-prd.oss-cn-shenzhen.aliyuncs.com/img/course_standard/default/default_${courseStandard.id.toString().substr(-1)}.png`"/>
            </div>
            <div style="width: 75%;" class="info">
              <div style="font-weight: bold;font-size:18px">
                <span v-if="courseStandard.awarded" style="font-size: 12px;color: #fbbb00;margin-right: 5px;border: 1px solid;padding-left: 2px;padding-right: 2px;vertical-align: middle;">精</span>
                <span style="vertical-align: middle;">{{courseStandard.name}}</span>
              </div>
              <div class="summary">{{courseStandard.summary}}</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import clone from 'clone'
import DictApi from '@/api/src/dict/api/DictApi.js'
import CourseStandardApi from '@/api/src/fragment/api/CourseStandardApi.js'
const dictApi = new DictApi()
const courseStandardApi = new CourseStandardApi();
export default {
  name: 'CourseHistory',
  data() {
    return {
      loading: false,
      finished: false,
      pageNum: 1,
      courseList: [],
    }
  },
  async mounted() {
    this.pageNum = 1
    await this.onLoad(1)
  },
  methods: {
    async onLoad(page) {
      page = page || this.pageNum + 1

      let resp = await courseStandardApi.browsingHistory(page)

      this.pageNum = page
      if(!resp.hasNextPage){
        this.finished = true
      }else{  
        this.finished = false
      }

      this.totalCourse = resp.total
      console.log(this.pageNum)
      if(this.pageNum == 1) {
        this.courseList = resp.list
      }else{
        this.courseList = this.courseList.concat(resp.list)
      }
      this.loading = false
    },
    goKnowledgeDetail(courseStandardId) {
      this.$router.push({name: 'knowledgeMap', query: {courseStandardId}})
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  .course_container {
    border-radius: 5px;
    box-shadow: 0px 2px 6px 0px #cccccc;
    padding: 10px;
    .header {
      display: flex;
      justify-content: space-between;
    }
    .container {
      margin-top: 20px;
	  .scroll-container {
		  height: calc(100vh - 340rpx);
	  }
      .course_item {
        display: flex;
        // border-radius: 5px;
        // box-shadow: 3px 3px 3px 0px #cccccc;
        margin-top: 20px;
        img{
          width: 100%;
        }
        .info {
          padding: 0px 0 0px 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .summary {
            font-size:12px;
            color:#666666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            min-height: 68px;
          }
        }
      }
    }
  }
  .category_tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 42px;
    line-height: 42px;
    // padding-bottom: 5px;
    // padding-top: 5px;
    .category_item {
      flex: 1;
	  font-size: 16px;
      // border-radius: 1rem;
      height: 42px;
      line-height: 42px;
      text-align: center;
      &:first-child {
		margin-right: 10px;
        // border-radius: 1rem 0 0 1rem;
      }
      &:last-child {
		margin-left: 10px;
        // border-radius: 0 1rem 1rem 0;
      }
    }
    .active {
		color: #FF8826;
      border-bottom: 2px solid #fe8726;
    }
  }
}
</style>
  