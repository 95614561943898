<template>
  <div class="main">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Container',
  data() {
    return {
      navTitle: '首页'
    }
  }
}
</script>
