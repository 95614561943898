export default {
  namespaced: true,
  state: {
    userInfo: {
      msg: 111
    },
  },
  getters: {
  },
  mutations: {
    changeUserInfo: (state, userInfo) => {
      state.userInfo = userInfo
      if(userInfo.token) {
        localStorage.setItem('Authorization', userInfo.token)
      }
    },
  },
  actions: {
  }
}