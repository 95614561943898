/**
 *
 * OpenAPI spec version: 0.2.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// import es6Promise from 'es6-promise';
import JSONbig from 'json-bigint';
import BigNumber from 'bignumber.js';
import fetch from 'isomorphic-fetch';


  // es6Promise.polyfill();


  /**
   * @module ApiClient
   * @version 0.2.0
   */

  /**
   * Manages low level client-server communications, parameter marshalling, etc. There should not be any need for an
   * application to use this class directly - the *Api and model classes provide the public API for the service. The
   * contents of this file should be regarded as internal but are documented for completeness.
   * @alias module:ApiClient
   * @class
   */
  var exports = function () {
    /**
     * The base URL against which to resolve every API call's (relative) path.
     * @type {String}
     * @default http://localhost/api/admin/exam
     */
    //this.basePath = location.protocol + '//' + location.host;

    /**
     * The authentication methods to be included for all API calls.
     * @type {Array.<String>}
     */
    this.authentications = {
    };
    /**
     * The default HTTP headers to be included for all API calls.
     * @type {Array.<String>}
     * @default {}
     */
    this.defaultHeaders = {
    };

    /**
     * The default HTTP timeout for all API calls.
     * @type {Number}
     * @default 60000
     */
    this.timeout = 60000;
  };

  /**
   * Returns a string representation for an actual parameter.
   * @param param The actual parameter.
   * @returns {String} The string representation of <code>param</code>.
   */
  exports.prototype.paramToString = function (param) {
    if (param == undefined || param == null) {
      return '';
    }
    if (param instanceof Date) {
      return param.toJSON();
    }
    return param.toString();
  };

  /**
   * Builds full URL by appending the given path to the base URL and replacing path parameter place-holders with parameter values.
   * NOTE: query parameters are not handled here.
   * @param {String} path The path to append to the base URL.
   * @param {Object} pathParams The parameter values to append.
   * @returns {String} The encoded path with parameter values substituted.
   */
  exports.prototype.buildUrl = function (path, pathParams) {
    if (!path.match(/^\//)) {
      path = '/' + path;
    }
    //var url = location.protocol + '//' + location.host + path;
    var url = this.basePath + path;
    var _this = this;
    url = url.replace(/\{([\w-]+)\}/g, function (fullMatch, key) {
      var value;
      if (pathParams.hasOwnProperty(key)) {
        value = _this.paramToString(pathParams[key]);
      } else {
        value = fullMatch;
      }
      return encodeURIComponent(value);
    });
    return url;
  };

  /**
   * Checks whether the given content type represents JSON.<br>
   * JSON content type examples:<br>
   * <ul>
   * <li>application/json</li>
   * <li>application/json; charset=UTF8</li>
   * <li>APPLICATION/JSON</li>
   * </ul>
   * @param {String} contentType The MIME content type to check.
   * @returns {Boolean} <code>true</code> if <code>contentType</code> represents JSON, otherwise <code>false</code>.
   */
  exports.prototype.isJsonMime = function (contentType) {
    return Boolean(contentType != null && contentType.match(/^application\/json(;.*)?$/i));
  };

  /**
   * Chooses a content type from the given array, with JSON preferred; i.e. return JSON if included, otherwise return the first.
   * @param {Array.<String>} contentTypes
   * @returns {String} The chosen content type, preferring JSON.
   */
  exports.prototype.jsonPreferredMime = function (contentTypes) {
    for (var i = 0; i < contentTypes.length; i++) {
      if (this.isJsonMime(contentTypes[i])) {
        return contentTypes[i];
      }
    }
    return contentTypes[0];
  };

  /**
   * Checks whether the given parameter value represents file-like content.
   * @param param The parameter to check.
   * @returns {Boolean} <code>true</code> if <code>param</code> represents a file.
   */
  exports.prototype.isFileParam = function (param) {
    // fs.ReadStream in Node.js (but not in runtime like browserify)
    if (typeof window === 'undefined' &&
      typeof require === 'function'
      //&& require('fs') &&
      //param instanceof require('fs').ReadStream
    ) {
      return true;
    }
    // Buffer in Node.js
    if (typeof Buffer === 'function' && param instanceof Buffer) {
      return true;
    }
    // Blob in browser
    if (typeof Blob === 'function' && param instanceof Blob) {
      return true;
    }
    // File in browser (it seems File object is also instance of Blob, but keep this for safe)
    if (typeof File === 'function' && param instanceof File) {
      return true;
    }
    return false;
  };

  /**
   * Normalizes parameter values:
   * <ul>
   * <li>remove nils</li>
   * <li>keep files and arrays</li>
   * <li>format to string with `paramToString` for other cases</li>
   * </ul>
   * @param {Object.<String, Object>} params The parameters as object properties.
   * @returns {Object.<String, Object>} normalized parameters.
   */
  exports.prototype.normalizeParams = function (params) {
    var newParams = {};
    for (var key in params) {
      if (params.hasOwnProperty(key) && params[key] != undefined && params[key] != null) {
        var value = params[key];
        if (this.isFileParam(value) || Array.isArray(value)) {
          newParams[key] = value;
        } else {
          newParams[key] = this.paramToString(value);
        }
      }
    }
    return newParams;
  };

  /**
   * Enumeration of collection format separator strategies.
   * @enum {String}
   * @readonly
   */
  exports.CollectionFormatEnum = {
    /**
     * Comma-separated values. Value: <code>csv</code>
     * @const
     */
    CSV: ',',
    /**
     * Space-separated values. Value: <code>ssv</code>
     * @const
     */
    SSV: ' ',
    /**
     * Tab-separated values. Value: <code>tsv</code>
     * @const
     */
    TSV: '\t',
    /**
     * Pipe(|)-separated values. Value: <code>pipes</code>
     * @const
     */
    PIPES: '|',
    /**
     * Native array. Value: <code>multi</code>
     * @const
     */
    MULTI: 'multi'
  };

  /**
   * Builds a string representation of an array-type actual parameter, according to the given collection format.
   * @param {Array} param An array parameter.
   * @param {module:ApiClient.CollectionFormatEnum} collectionFormat The array element separator strategy.
   * @returns {String|Array} A string representation of the supplied collection, using the specified delimiter. Returns
   * <code>param</code> as is if <code>collectionFormat</code> is <code>multi</code>.
   */
  exports.prototype.buildCollectionParam = function buildCollectionParam(param, collectionFormat) {
    if (param == null) {
      return null;
    }
    switch (collectionFormat) {
      case 'csv':
        return param.map(this.paramToString).join(',');
      case 'ssv':
        return param.map(this.paramToString).join(' ');
      case 'tsv':
        return param.map(this.paramToString).join('\t');
      case 'pipes':
        return param.map(this.paramToString).join('|');
      case 'multi':
        // return the array directly as SuperAgent will handle it as expected
        return param.map(this.paramToString);
      default:
        throw new Error('Unknown collection format: ' + collectionFormat);
    }
  };



  exports.prototype.buildUrlParams = function (params) {
    if (!params) {
      return null;
    }
    var paramStr = '';

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        paramStr += '&' + key + '=' + encodeURIComponent(params[key]);
      }
    }
    return paramStr.substr(1);
  };

  exports.prototype.buildFormParams = function (params) {
    if (!params) {
      return null;
    }
    var form = new FormData()
    form.append('file', input.files[0])
    form.append('user', 'hubot')

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        form.append(key, params[key]);
      }
    }
    return form;
  };

  /**
   * Applies authentication headers to the request.
   * @param {Object} request The request object created by a <code>superagent()</code> call.
   * @param {Array.<String>} authNames An array of authentication method names.
   */
  exports.prototype.applyAuthToRequest = function (request, authNames) {
    var _this = this;
    authNames.forEach(function (authName) {
      var auth = _this.authentications[authName];
      switch (auth.type) {
        case 'basic':
          if (auth.username || auth.password) {
            request.auth(auth.username || '', auth.password || '');
          }
          break;
        case 'apiKey':
          if (auth.apiKey) {
            var data = {};
            if (auth.apiKeyPrefix) {
              data[auth.name] = auth.apiKeyPrefix + ' ' + auth.apiKey;
            } else {
              data[auth.name] = auth.apiKey;
            }
            if (auth['in'] === 'header') {
              request.set(data);
            } else {
              request.query(data);
            }
          }
          break;
        case 'oauth2':
          if (auth.accessToken) {
            request.set({ 'Authorization': 'Bearer ' + auth.accessToken });
          }
          break;
        default:
          throw new Error('Unknown authentication type: ' + auth.type);
      }
    });
  };

  /**
   * Invokes the REST service using the supplied settings and parameters.
   * @param {String} path The base URL to invoke.
   * @param {String} httpMethod The HTTP method to use.
   * @param {Object.<String, String>} pathParams A map of path parameters and their values.
   * @param {Object.<String, Object>} queryParams A map of query parameters and their values.
   * @param {Object.<String, Object>} headerParams A map of header parameters and their values.
   * @param {Object.<String, Object>} formParams A map of form parameters and their values.
   * @param {Object} bodyParam The value to pass as the request body.
   * @param {Array.<String>} authNames An array of authentication type names.
   * @param {Array.<String>} contentTypes An array of request MIME types.
   * @param {Array.<String>} accepts An array of acceptable response MIME types.
   * @param {(String|Array|ObjectFunction)} returnType The required type to return; can be a string for simple types or the
   * constructor for a complex type.
   * @returns {Promise} A {@link https://www.promisejs.org/|Promise} object.
   */
  exports.prototype.callApi = function callApi(path, httpMethod, pathParams,
    queryParams, headerParams, formParams, bodyParam, authNames, contentTypes, accepts,
    returnType, requireLogin) {
    
    //if(typeof window == 'undefined'){
    //  let als = require('async-local-storage');
    //  let auth = als.get('auth');
    //  if(auth && auth.api_key){
    //    queryParams.api_key = auth.api_key;
    //  }
    //  if(auth && auth.sessionId){
    //   headerParams.Cookie = `sid=${auth.sessionId}`;
    //  }
    //}else{
    if(process.env.NODE_ENV == 'development') {
      this.basePath = 'https://account-stg.fmyundianji.com'
    }else{
      // this.basePath = 'https://account-stg.fmyundianji.com'
      this.basePath = 'https://account-prd.fmyundianji.com'
    }
      // this.basePath = location.protocol + "//" + location.host;
    //}

    var originBodyParam = bodyParam;
    var _this = this;
    var url = this.buildUrl(path, pathParams);
    //// var request = superagent(httpMethod, url);

    // apply authentications
    // TODO

    // add timestamp                                
    if (httpMethod === 'GET') {
      queryParams._t = new Date().getTime();
    }

    // set query parameters
    url += (url.indexOf('?') === -1 ? '?' : '&') +
      this.buildUrlParams(this.normalizeParams(queryParams));

    // set header parameters
    ///header parameters
    var headers = Object.assign({}, this.defaultHeaders,
      this.normalizeParams(headerParams));

    // set request timeout
    /// request.timeout(this.timeout);

    var accept = this.jsonPreferredMime(accepts);
    if (accept) {
      headers['Accept'] = accept;
    }

    var contentType = this.jsonPreferredMime(contentTypes) || 'application/json';
    headers['Content-Type'] = contentType;

    if (contentType === 'application/x-www-form-urlencoded' ||
      contentType == 'multipart/form-data') {
      bodyParam = this.buildFormParams(this.normalizeParams(formParams));
    } else {
      function replacer(key, value) {
        if (value === null) {
          return undefined;
        }
        return value;
      }
      if(typeof bodyParam != 'string'){
        bodyParam = bodyParam ? JSONbig.stringify(bodyParam, replacer) : null;
      }
    }

    function status(response) {
      if (response.status >= 200 && response.status < 300) {
      	//if(typeof window == 'undefined'){
	    //    let als = require('async-local-storage');
	    //    let cookies = response.headers.getAll('set-cookie');
	    //    cookies.map(cookie => {
	    //      if(cookie.indexOf('sid') == 0){
	    //        let cookie_arr = cookie.split(';');
	    //        let serverSessionId = cookie_arr[0].substr(4, cookie_arr[0].length);
	    //        let auth = als.get('auth');
	    //        auth.serverSessionId = serverSessionId;
	            // als.set('auth');
	    //      }
	    //    })
      	//}
        return response;
      }
      return exports.ajaxError(response);
    }

    function json(response) {
      return response.text().then(function (text) {
        var json;
        try{
          json = JSONbig.parse(text);
        }catch(e){
          json = text;
        }
        return exports.convertToType(json, returnType);
      });
    }

    return fetch(url, {
        method: httpMethod,
        headers: headers,
        body: bodyParam,
        credentials: 'include'
      }).then(status).then(json);
  };

  /**
   * Parses an ISO-8601 string representation of a date value.
   * @param {String} str The date value as a string.
   * @returns {Date} The parsed date object.
   */
  exports.parseDate = function (str) {
    return new Date(str);
  };

  /**
   * Converts a value to the specified type.
   * @param {(String|Object)} data The data to convert, as a string or object.
   * @param {(String|Array.<String>|Object.<String, Object>|Function)} type The type to return. Pass a string for simple types
   * or the constructor function for a complex type. Pass an array containing the type name to return an array of that type. To
   * return an object, pass an object with one property whose name is the key type and whose value is the corresponding value type:
   * all properties on <code>data<code> will be converted to this type.
   * @returns An instance of the specified type.
   */
  exports.convertToType = function (data, type) {
    switch (type) {
      case 'Boolean':
        return Boolean(data);
      case 'Integer':
        if (data == null) {
          return data;
        }
        if(data instanceof BigNumber){
          return data.toString();
        }
        var i = parseInt(data, 10);
        if(i >= 9007199254740992){
          return new BigNumber("" + data);
        }
        return i;
      case 'Number':
        if (data == null) {
          return data;
        }
        return parseFloat(data);
      case 'String':
        return data ? String(data) : data;
      case 'Date':
        if (data == null) {
          return data;
        }
        return this.parseDate(String(data));
      default:
        if (type === Object) {
          // generic object, return directly
          return data;
        } else if (typeof type === 'function') {
          // for model type like: User
          return type.constructFromObject(data);
        } else if (Array.isArray(type)) {
          // for array type like: ['String']
          var itemType = type[0];
          if (!data) {
            data = [];
          }
          return data.map(function (item) {
            return exports.convertToType(item, itemType);
          });
        } else if (typeof type === 'object') {
          // for plain object type like: {'String': 'Integer'}
          var keyType, valueType;
          for (var k in type) {
            if (type.hasOwnProperty(k)) {
              keyType = k;
              valueType = type[k];
              break;
            }
          }
          var result = {};
          for (var k in data) {
            if (data.hasOwnProperty(k)) {
              var key = exports.convertToType(k, keyType);
              var value = exports.convertToType(data[k], valueType);
              result[key] = value;
            }
          }
          return result;
        } else {
          // for unknown type, return the data directly
          return data;
        }
    }
  };

  /**
   * Constructs a new map or array model from REST data.
   * @param data {Object|Array} The REST data.
   * @param obj {Object|Array} The target object or array.
   */
  exports.constructFromObject = function (data, obj, itemType) {
    if (Array.isArray(data)) {
      for (var i = 0; i < data.length; i++) {
        if (data.hasOwnProperty(i))
          obj[i] = exports.convertToType(data[i], itemType);
      }
    } else {
      for (var k in data) {
        if (data.hasOwnProperty(k))
          obj[k] = exports.convertToType(data[k], itemType);
      }
    }
  };

  /**
   * 全局处理ajax错误.
   * @param response {Object} rest response.
   */
  exports.ajaxError = function (response) {
    return response.json().then(function (data) { throw data });
  };

  /**
   * The default API client implementation.
   * @type {module:ApiClient}
   */
  exports.instance = new exports();

  export default exports;
